import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ApiModel from "../Utils/ApiModel";
import { Colors } from "../Utils/AppConstant";
import Loader from "../components/loader";

function Readblog() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      get_blog();
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const get_blog = async () => {
    setIsLoading(true);
    ApiModel.sendApiCall(
      `blog`,
      null,
      "GET",
      (response) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (response?.data?.status === 200) {
          setData(response?.data?.data);
        } else {
          setIsLoading(false);
          setData([]);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error is ===>", error);
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section class="blog-area pt-100 pb-70">
          <div class="container ">
            <div class="section-title text-center">
              {/* <i class="flaticon-leaf"></i> */}
              <img
                src="assets/images/Group-114.png"
                className="vert-move"
              ></img>
              <h2>Read Our Blog</h2>
              <p>
                If you’ve ever read a blog post, you’ve consumed content from a
                thought leader that is an expert in their industry. Chances are
                if the blog post was written effectively, you came away with
                helpful knowledge and a positive opinion about the writer or
                brand that produced the content.
              </p>
            </div>
            <div class="row pt-45 justify-content-center">
              {data?.map((object, index) => (
                <div class="col-lg-3 col-md-4 col-6">
                  <div class="blog-card blog-color-2">
                    <a href="#">
                      <img loading="lazy" src={object.image} alt="Images" />
                    </a>
                    <div class="content">
                      <p
                        style={{ color: Colors.textColor, fontWeight: "bold" }}
                      >
                        {/* {object.title} */}
                        {object.title.length > 50
                          ? object.title.substring(0, 50) + "..."
                          : object.title}
                      </p>
                      <Link
                        className="link-h"
                        style={{ fontWeight: "bold", fontSize: 14 }}
                        to={{
                          pathname: "/blogs",
                          state: { data: object },
                        }}
                      >
                        Read More
                      </Link>
                      {/* <a href="/blogs" class="read-btn">Read More</a> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Readblog;
