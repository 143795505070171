import React from "react";

function loader() {
  return (
    <>
      <div class="preloader">
        <div class="d-table">
          <div class="d-table-cell">
            <img src="assets/images/41021.jpg" alt="Images" />
            <h2>Pedh Paudha</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default loader;
