import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
function OrderSuccessfully() {
  return (
    <>
      <Header />
      <section className="order-succ">
        <div class="choose-area pt-100 pb-70">
          <div class="container">
            <div class="section-title text-center">
              <h2>Order Successfully</h2>
            </div>
            <div class="row pt-25 top-padd">
              <div class="col-lg-5 col-sm-5 mx-auto">
                <div class="choose-card">
                  <img src="assets/images/card-s.gif"></img>
                  <h3>Thank You!</h3>
                  <p>Your order has been placed successfully.</p>
                  <p style={{ color: "red", fontSize: 13, marginTop: -25 }}>
                    NOTE: Orders on saturday and sunday will be delivered on
                    monday.
                  </p>
                  <a href="/" class="default-btn btn-bg-three btn-shooping">
                    Contiune Shopping
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default OrderSuccessfully;
