import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PrivacyPolicy from "./privacy-policy";
import ApiModel from "../Utils/ApiModel";
import { toast_func } from "../Utils/Toastify/toaster";
import { color } from "@mui/system";
import { Colors } from "../Utils/AppConstant";
import { ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "../components/loader";
window.scrollTo(0, 0);

function Profile() {
  const value = window.localStorage.getItem("userdata");
  const userData = JSON.parse(value);
  const [address, setData] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [profile, setProfile] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [street, setStreet] = useState("");
  const [landmark, setLandmark] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [close, setClose] = useState(false);
  const [orders, setOrders] = useState([]);
  const [reData, setReData] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [onDeleted, setOnDelete] = useState([]);
  const [reuse, setReuse] = useState([]);
  const history = useHistory();
  const [status, setStatus] = useState(1);
  const [profileDta, setProfileDta] = useState("");
  const [fullName, setFullname] = useState("");
  const [no, setNo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigateTo = () => history.push("/login");

  const addAdrress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    if (name === "") {
      alert("Please enter your full name");
    } else if (mobile === "") {
      alert("Please enter your valid mobile number");
    } else if (street === "") {
      alert("Please enter your valid email id");
    } else if (landmark === "") {
      alert("Please enter your landmark");
    } else if (state === "") {
      alert("Please enter your state name");
    } else if (pinCode === "") {
      alert("Please enter your pincode");
    } else {
      let body = {
        name: name,
        phone: mobile,
        state: state,
        pincode: pinCode,
        city: street,
        address: landmark,
        userid: finalId?.userId,
      };
      ApiModel.sendApiCall(
        `addressadd`,
        body,
        null,
        (result) => {
          if (result?.status === 200) {
            setClose(true);
            setReuse(result);
            get_address();
            toast_func("success", "Address added successfully!");
            setName("");
            setMobile("");
            setLandmark("");
            setPinCode("");
            setStreet("");
            setState("");
          } else {
            console.log("te error is==>");
          }
        },
        (error) => {
          console.log("the error is ===>", error);
        }
      );
    }
  };

  console.log("anshullllaaaà", address);

  const deleteAddress = async (item, index) => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    var txt;
    if (window.confirm("Are you sure want to delete address!")) {
      txt = "You pressed OK!";
      ApiModel.sendApiCall(
        `deleteaddress/${item?.id}`,
        null,
        "GET",
        (response) => {
          console.log("---------------hhhh", response?.data?.status);
          if (response?.data?.status == 200) {
            get_address();
            toast_func("success", "Address deleted successfully!");
          } else {
            // setData([])
          }
        },
        (error) => {
          // console.log('the error in blog page is===>',error)
        }
      );
    } else {
      txt = "You pressed Cancel!";
    }
  };

  const get_address = async () => {
    // setIsLoading(true)
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `getaddtess/${userData?.id}`,
      null,
      "GET",
      (response) => {
        // setIsLoading(false)
        if (response?.data?.status === 200) {
          setData(response?.data?.result);
        } else {
          // setIsLoading(false)
          setData([]);
          toast_func("Fuction is not working");
        }
      },
      (error) => {
        // setIsLoading(false)
      }
    );
  };

  const get_orders = async () => {
    // setIsLoading(true)
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `orderhistory/${finalId?.userId}/${status}`,
      null,
      "GET",
      (response) => {
        // setIsLoading(false)
        if (response?.data?.status === 200) {
          setOrders(response?.data?.orders);
        } else {
          // setIsLoading(false)
          setData([]);
          toast_func("Fuction is not working");
          
        }
      },
      (error) => {
        // setIsLoading(false)
      }
    );
  };

  const get_wishlist = async () => {
    // setIsLoading(true)
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `getwishlist/${finalId?.userId}`,
      null,
      "GET",
      (response) => {
        // setIsLoading(false)
        if (response?.data?.status === 200) {
          setWishlist(response?.data?.items);
        } else {
          // setIsLoading(false)
          setWishlist([]);
        }
      },
      (error) => {
        // setIsLoading(false)
        console.log("the error in blog page is===>", error);
      }
    );
  };

  const get_profile = async () => {
    setIsLoading(true);
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `getprofile/${finalId?.userId}`,
      null,
      "GET",
      (response) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (response?.data?.status === 200) {
          setProfile(response?.data?.data);
        } else {
          setIsLoading(false);
          setWishlist([]);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error in blog page is===>", error);
      }
    );
  };

  const addToCart = async (item) => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    let body = {
      id: item?.productid,
      img: item?.img,
      name: item?.name,
      stock: 0,
      offerPrice: item?.offerPrice,
      actualPrice: item?.actualPrice,
      qty: 1,
      userid: finalId?.userId,
    };
    ApiModel.sendApiCall(
      `addtocart`,
      body,
      null,
      (result) => {
        if (result?.data?.status === 200) {
          setReData(result?.data);
          // localStorage.setItem('addtocart', JSON.stringify(result?.data?.status+1));
          toast_func("success", result?.data?.success);
        } else {
          toast_func("warning", result?.data?.sussess);
        }
      },
      (error) => {
        console.log("the error is ==HELLA=>", error);
      }
    );
  };

  const onDetails = (item) => {
    setOrderDetails(item);
  };

  const onDelete = async (item) => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `removewishlist/${item?.productid}/${finalId?.userId}`,
      null,
      "GET",
      (response) => {
        if (response?.data?.status === 200) {
          setOnDelete(response?.data);
          get_wishlist();
          toast_func("success", response?.data?.sussess);
        } else {
          toast_func("warning", response?.data?.sussess);
        }
      },
      (error) => {}
    );
  };

  useMemo(() => {
    get_address();
    get_profile();
    get_wishlist();
  }, []); //address, reuse

  useMemo(() => {
    get_orders();
  }, [status]);

  const logout = () => {
    localStorage.removeItem("userdata");
    localStorage.clear();
    navigateTo();
    toast_func("success", "logout Successfully!");
  };

  const onUpdatedPro = (i) => {
    console.log("------------saaddam", i?.name);
    setFullname(i?.name);
    setNo(i?.phone);
    setEmail(i?.email);
    setPassword(i?.password);
  };

  const updateProfile = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    // if (fullName == '') {
    //   alert('Please enter your full name');
    // } else if (no == '') {
    //   alert('Please enter your valid mobile number');
    // } else if (email == '') {
    //   alert('Please enter your valid email id');
    // } else {
    // alert('Hit2')
    let body = {
      id: finalId?.userId,
      name: fullName,
      email: email,
      phone: no,
      password: password,
    };

    ApiModel.sendApiCall(
      `updateprofile`,
      body,
      null,
      (result) => {
        if (result?.data?.status === 200) {
          get_profile();
          toast_func("success", "Profile updated successfully!");
        } else {
          // toast_func("warning", result?.data?.sussess)
        }
      },
      (error) => {
        console.log("the error is ===>", error);
      }
    );
  };

  return (
    //   <>
    // {isLoading ? <Loader/> :
    <>
      <ToastContainer />
      <Header data={reData} pro={"profile"} />

      <section className="profile">
        <div class="container mt-3">
          <div className="row box-profile">
            <div class="col-md-4">
              <ul class="nav nav-tabs" id="sidebar-nav">
                <li class="nav-item">
                  <a class="nav-link  nav-nav active" href="#home">
                    <img
                      src={
                        profile?.image
                          ? profile?.image
                          : "assets/images/profilePic.gif"
                      }
                      alt="Images"
                    />
                    {profile?.name}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-nav" href="#order">
                    {" "}
                    <i class="bx bx-cart"></i>My Order
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link nav-nav" href="#menu2">
                    {" "}
                    <i class="bx bx-map"></i>My Address
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-nav" href="#menuss">
                    <i class="bx bx-heart"></i>Wishlist
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link nav-nav" href="#menu3">
                    <i class="bx bx-file"></i>Privacy Policy
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-nav" href="#menu4">
                    <i class="bx bx-support"></i>Help & Support
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    onClick={() => logout()}
                    class="nav-link nav-nav"
                    href="#menu5"
                  >
                    <i class="bx bx-log-in"></i>Logout
                  </a>
                </li>

                <div className="imag-srcss">
                  <img src="assets/images/Side-Plant.png"></img>
                </div>
              </ul>
            </div>

            <div class="col-md-8">
              <div class="tab-content">
                <div id="home" class="container tab-pane  active">
                  <div class="account-img">
                    <div className="image">
                      <h3>Edit Profile</h3>
                      {/* {console.log('--------------gkjnlkfg,n', profile?.image)} */}
                      <img
                        src={
                          profile?.image
                            ? profile?.image
                            : "assets/images/profilePic.gif"
                        }
                        alt="Images"
                      />
                      <i
                        onClick={() => onUpdatedPro(profile)}
                        class="bx bx-pencil"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModald"
                      ></i>
                    </div>
                  </div>

                  <div class="account-tab-item">
                    <div class="account-details">
                      <div class="account-form gro">
                        <form>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group gropup ">
                                <div className="text-para">
                                  <p>{profile?.name}</p>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group gropup">
                                <div className="text-para">
                                  <p>{profile?.phone}</p>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group gropup">
                                <div className="text-para">
                                  <p>{profile?.email}</p>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12"></div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="order" class=" tab-pane fade">
                  <div class="layout--tabs">
                    <div class="container">
                      <div class="header">
                        <h1>My Orders</h1>
                      </div>

                      <div class="nav-tabs-wrapper">
                        <ul
                          class="nav nav-tabs tabs-n"
                          id="tabs-title-region-nav-tabs"
                          role="tablist"
                        >
                          <li class="nav-item">
                            <a
                              onClick={() => setStatus(1)}
                              class="nav-link active"
                              data-toggle="tab"
                              role="tab"
                              href="#block-simple-text-1"
                              aria-selected="false"
                              aria-controls="block-simple-text-1"
                              id="block-simple-text-1-tab"
                            >
                              All
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              onClick={() => setStatus(2)}
                              class="nav-link"
                              data-toggle="tab"
                              role="tab"
                              href="#block-simple-text-2"
                              aria-selected="false"
                              aria-controls="block-simple-text-2"
                              id="block-simple-text-2-tab"
                            >
                              Delivered
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              onClick={() => setStatus(3)}
                              class="nav-link"
                              data-toggle="tab"
                              role="tab"
                              href="#block-simple-text-3"
                              aria-selected="false"
                              aria-controls="block-simple-text-3"
                              id="block-simple-text-3-tab"
                            >
                              Cancelled
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="card card-whis">
                        <div class="card-body">
                          <div class="tab-content">
                            <div
                              id="block-simple-text-1"
                              class="tab-pane active block block-layout-builder block-inline-blockqfcc-blocktype-simple-text"
                              role="tabpanel"
                              aria-labelledby="block-simple-text-1-tab"
                            >
                              {console.log("------------orderrr")}

                              {
                                orders?.length != 0 ? (
                                  <>
                                    {orders?.map((i, index) => (
                                      <>
                                        {/* {i?.id != '' ? 
                    <> */}
                                        <table class="table table-bordered">
                                          <tbody>
                                            <div class="cart-table order-table order-table-2">
                                              <div class="table-responsive">
                                                <table class="table mb-0">
                                                  <tbody>
                                                    <tr>
                                                      <td class="product-detail">
                                                        <div class="product border-0">
                                                          <a class="product-image">
                                                            <img
                                                              src="assets/images/commanImg.png"
                                                              class="img-fluid blur-up lazyloaded"
                                                              alt=""
                                                            />
                                                          </a>
                                                          <div class="product-detail">
                                                            <ul>
                                                              <li class="order-li">
                                                                <a>
                                                                  Order:
                                                                  {i?.orderid}
                                                                </a>
                                                              </li>
                                                              {/* <li class="text-content price">Lorem Ipsum</li> */}
                                                              <li class="text-content price item">
                                                                {
                                                                  i?.history
                                                                    ?.items
                                                                    .length
                                                                }{" "}
                                                                Item
                                                              </li>
                                                              <li class="text-content ">
                                                                <span class="price pri">
                                                                  {i?.orderDate}
                                                                </span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td class="subtotal sub-total">
                                                        <h4 class="table-title text-content conttt">
                                                          Total Amount.
                                                        </h4>
                                                        <p className="pricess">
                                                          ₹{i?.totalamt}
                                                        </p>
                                                        <a
                                                          onClick={() =>
                                                            onDetails(i)
                                                          }
                                                          class="default-btn btn-bg-three table-title text-content content-add details-btn b0x-df  btn-hover"
                                                          data-bs-toggle="modal"
                                                          data-bs-target="#exampleModalgg"
                                                        >
                                                          Details
                                                        </a>

                                                        {i?.status == 2 ? (
                                                          <a class="default-btn btn-bg-three table-title text-content content-add deli ">
                                                            Delivered
                                                          </a>
                                                        ) : i?.status == 3 ? (
                                                          <a class="default-btn btn-bg-three table-title text-content content-add deli  cancel">
                                                            Cancelled
                                                          </a>
                                                        ) : i?.status == 1 ? (
                                                          <a class="default-btn btn-bg-three table-title text-content content-add deli on-the ">
                                                            On the way
                                                          </a>
                                                        ) : (
                                                          <a class="default-btn btn-bg-three table-title text-content content-add deli  order-palce ">
                                                            Order Place
                                                          </a>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </tbody>
                                        </table>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <div class="row">
                                    <div class="col-lg-10 col-md-10  mx-auto">
                                      <div class="box-sss box-w">
                                        <img
                                          src="assets/images/Address.gif"
                                          style={{ height: 270, width: 300 }}
                                          class="img-fluid blur-up lazyloaded"
                                          alt=""
                                        />
                                        <p
                                          class="para"
                                          style={{ fontWeight: "600" }}
                                        >
                                          No orders yet
                                        </p>
                                        <p
                                          style={{ marginTop: -20 }}
                                          class="para"
                                        >
                                          Looks like haven't made your choice
                                          yet
                                        </p>
                                        <div class="btn-gru gru-btn">
                                          <a
                                            href="/product"
                                            class="read-btn default-btn"
                                          >
                                            Start Shopping
                                          </a>
                                        </div>
                                        {/* <p class="para" style={{fontWeight:'600'}}>You haven't added any product in the cart</p> */}
                                      </div>
                                    </div>
                                  </div>
                                )
                                // <img src="assets/images/animation_500_lgm81f52.gif" style={{height:400, width:400}} class="img-fluid blur-up lazyloaded" alt=""/>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="menu1" class="container tab-pane fade">
                  <div className="tab-content con-teb">
                    <h1>My Address</h1>
                    <div className="mis-use">
                      <h3>Gazing Technosoft</h3>
                      <p>Procapitu</p>
                    </div>
                  </div>
                </div>

                {/* Address section */}

                <div id="menu2" class="container tab-pane fade">
                  <div className="tab-content con-teb">
                    <h1>My Address</h1>

                    {console.log("----------isADDRESS", address)}

                    {address?.length != 0 ? (
                      <>
                        {address?.map((i, index) => (
                          <>
                            <div className="mis-use">
                              <div class="radio">
                                <input
                                  id="radio-1"
                                  name="radio"
                                  type="radio"
                                  className=""
                                />
                                <label for="radio-1" class="radio-label">
                                  <span class="title-sec">{i?.name}</span>
                                </label>
                              </div>
                              <p>
                                {i?.city}, {i?.address}, {i?.state},{" "}
                                {i?.pincode}
                              </p>
                              {/* <a class="read-btn">Change </a> */}
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteAddress(i)}
                                class="read-btn"
                              >
                                Remove
                              </a>
                            </div>
                            <div class="btn-gru gru-btn default-btn">
                              <a
                                class="read-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                Add New Address
                              </a>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <div class="row">
                          <div class="col-lg-10 col-md-10  mx-auto">
                            <div class="box-sss ">
                              <img
                                src="assets/images/animation_300_lgw5u07e.gif"
                                style={{ height: 270, width: 300 }}
                                class="img-fluid blur-up lazyloaded"
                                alt=""
                              />
                              {/* <p class="para" style={{fontWeight:'600'}}>Your wishlist is empty!</p> */}
                              <p style={{ marginTop: -20 }} class="para">
                                You don't have any address please add new
                                address first
                              </p>
                              <div class="btn-gru gru-btn ">
                                <a
                                  class="read-btn default-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Add New Address
                                </a>
                              </div>
                              {/* <p class="para" style={{fontWeight:'600'}}>You haven't added any product in the cart</p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div class="btn-gru"><a href="#" class="read-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Add New Address</a></div> */}
                </div>

                {/* Wishlist section */}

                <div id="menuss" class="container tab-pane fade">
                  <h1>Wishlist</h1>
                  {console.log(
                    "--------------maukaWishlisyt",
                    wishlist?.length
                  )}

                  {wishlist?.length != 0 ? (
                    <>
                      {wishlist?.map((i, index) => (
                        <section class="cart-wraps-area ptb-100 cartss profile-cart">
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-12 col-md-12">
                                <form>
                                  <div
                                    class="cart-wraps wraps-cart "
                                    style={{ padding: "0" }}
                                  >
                                    <div class="cart-table table-responsive">
                                      <table class="table table-bordered">
                                        <tbody>
                                          <div class="cart-table order-table order-table-2">
                                            <div class="table-responsive">
                                              <table class="table mb-0">
                                                <tbody>
                                                  <tr>
                                                    <td class="product-detail">
                                                      <div class="product border-0">
                                                        <a class="product-image">
                                                          <img
                                                            src={i?.img}
                                                            class="img-fluid blur-up lazyloaded"
                                                            alt=""
                                                          />
                                                        </a>
                                                        <div class="product-detail">
                                                          <ul>
                                                            {i?.qty >= 1 ? (
                                                              <li class="name">
                                                                <a>In Stock</a>
                                                              </li>
                                                            ) : (
                                                              <li class="name">
                                                                <a
                                                                  style={{
                                                                    color:
                                                                      Colors.red,
                                                                  }}
                                                                >
                                                                  Out of stock
                                                                </a>
                                                              </li>
                                                            )}

                                                            <li class="text-content price">
                                                              {i?.name}
                                                            </li>

                                                            <li class="text-content text-con">
                                                              4.5{" "}
                                                              <i class="bx bxs-star"></i>
                                                            </li>
                                                            <li class="text-content">
                                                              <span className="price">
                                                                ₹{i?.offerPrice}
                                                              </span>{" "}
                                                              <del className="del">
                                                                ₹
                                                                {i?.actualPrice}{" "}
                                                              </del>
                                                              <span className="Percentage">
                                                                50%
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td class="subtotal sub-total">
                                                      <h4 class="table-title text-content">
                                                        <i
                                                          onClick={() =>
                                                            onDelete(i)
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          class="bx bx-trash"
                                                        ></i>
                                                      </h4>
                                                      <a
                                                        onClick={() =>
                                                          addToCart(i)
                                                        }
                                                        class="default-btn btn-bg-three table-title text-content content-add"
                                                      >
                                                        Add to Cart
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </section>
                      ))}
                    </>
                  ) : (
                    <div class="row">
                      <div class="col-lg-10 col-md-10  mx-auto">
                        <div class="box-sss ">
                          <img
                            src="assets/images/wishlist.gif"
                            style={{ height: 270, width: 300 }}
                            class="img-fluid blur-up lazyloaded"
                            alt=""
                          />
                          <p class="para" style={{ fontWeight: "600" }}>
                            Your wishlist is empty!
                          </p>
                          <p style={{ marginTop: -20 }} class="para">
                            Explore more and shortlist some items
                          </p>
                          <div class="btn-gru gru-btn">
                            <a href="/product" class="read-btn default-btn">
                              Start Shopping
                            </a>
                          </div>
                          {/* <p class="para" style={{fontWeight:'600'}}>You haven't added any product in the cart</p> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div id="menu3" class="container tab-pane fade">
                  <div class="privacy-policy-area pt-10 ">
                    <div class="container">
                      <div class="row pt-45 pt-top">
                        <div class="col-lg-12">
                          <div class="single-content scrollbar" id="style-2">
                            <PrivacyPolicy />
                          </div>

                          <div className="center-s">
                            <button className="btn-under"> Understood </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="menu4" class="container tab-pane fade">
                  <div class="contact-area pb-70 contect-secss">
                    <div class="container">
                      <div class="row ">
                        <div class="newsletter-area-section  sec-news">
                          <div class="section-title text-center">
                            <h2>Hello How We Can Help?</h2>
                          </div>
                          <div class="newsletter-area pt-45">
                            {/* <form class="newsletter-form" data-toggle="validator" method="POST" novalidate="true"> */}
                            {/* <input type="email" class="form-control" placeholder="Ask a Question...." name="EMAIL" required="" autocomplete="off"/>
            <i class="bx bx-search"></i>
            <button class="subscribe-btn disabled" type="submit" >
            Search
            </button> */}

                            {/* </form> */}
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <div class="contact-card">
                            <i class="bx bx-phone-call"></i>
                            <h3>Call Number</h3>
                            <span>
                              <a href="tel:+120-408-0513">+120-408-0513</a>
                            </span>
                            {/* <span><a href="tel:+1-(123)-656-6790">+1 (123) 656 6790</a></span> */}
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <div class="contact-card">
                            <i class="bx bxs-map"></i>
                            <h3>Address</h3>
                            <span>302, D 247/A4</span>
                            <br></br>
                            <span>Procapitus Business Park</span>
                            <br></br>
                            <span>Sector 63, Noida Uttar Pradesh 201301</span>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                          <div class="contact-card">
                            <i class="bx bx-message"></i>
                            <h3>Contact Info</h3>
                            <span>
                              <a href="support@gazingtechnosoft.com">
                                support@gazingtechnosoft.com
                              </a>
                            </span>
                            {/* <span><a href="mailto:info@hilo.com">info@hilo.com</a></span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="menu5" class="container tab-pane fade">
                  <h3>Menu 2</h3>
                  <p>ddddddddddddd.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Add New Address
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group has-error">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  class="form-control"
                  required=""
                  data-error="Please enter your name"
                  placeholder="Enter Full Name"
                />
              </div>

              <div class="form-group has-error">
                <input
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  class="form-control"
                  required=""
                  data-error="Please enter your mobile number"
                  placeholder="Enter Your Mobile Number"
                />
              </div>

              <div class="form-group has-error">
                <input
                  type="text"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  required=""
                  data-error="Enter Your House no. / Colony"
                  placeholder="Enter Your House no. / Colony"
                />
              </div>
              <div class="form-group has-error">
                <input
                  type="text"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                  class="form-control"
                  required=""
                  data-error="Enter Your City"
                  placeholder="Enter Your City"
                />
              </div>
              <div class="form-group has-error">
                <input
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  class="form-control"
                  required=""
                  data-error="Enter Your State"
                  placeholder="Enter Your State"
                />
              </div>
              <div class="form-group has-error">
                <input
                  type="text"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                  class="form-control"
                  required=""
                  data-error="Enter Your PinCode"
                  placeholder="Enter Your PinCode"
                />
              </div>
              <div className=" btn-save">
                {pinCode != "" ? (
                  <button
                    onClick={() => {
                      addAdrress();
                    }}
                    data-bs-target="#exampleModal"
                    href="#menu2"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save New Address
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      addAdrress();
                    }}
                    type="button"
                    style={{ backgroundColor: "grey" }}
                    class="btn btn-primary"
                  >
                    Save New Address
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModald"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Update Profile
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="account-tab-item item-s">
                <div class="account-details">
                  <div class="account-form">
                    {/* <form> */}
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group has-error">
                          <input
                            class="form-control"
                            id="filled-basic"
                            value={fullName}
                            onChange={(e) => setFullname(e.target.value)}
                            placeholder="Enter Full Name"
                            variant="filled"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group has-error">
                          <input
                            class="form-control"
                            type="number"
                            id="filled-basic"
                            value={no}
                            onChange={(e) => setNo(e.target.value)}
                            placeholder="Enter Number"
                            variant="filled"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group has-error">
                          <input
                            class="form-control"
                            id="filled-basic"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Email Id"
                            variant="filled"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group has-error">
                          <input
                            class="form-control"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="filled-basic"
                            placeholder="Change Password"
                            variant="filled"
                          />
                        </div>
                      </div>
                      <div class="col-md-12 center">
                        <button
                          type="submit"
                          data-bs-dismiss="modal"
                          onClick={() => updateProfile()}
                          class="default-btn btn-bg-three"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModalgg"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Order Details
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="all-box">
                <section class="cart-wraps-area ptb-100 ptb-bootom ">
                  <div class="container all-con">
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <form>
                          <div class="dd" style={{ padding: "0" }}>
                            <div class="cart-table table-responsive cart-pagess">
                              <div className="imag-se">
                                <h1>Order Details</h1>
                                {orderDetails?.status == 1 ? (
                                  <img src="assets/images/on-the-way.png"></img>
                                ) : orderDetails?.status == 2 ? (
                                  <img src="assets/images/Delivered.png"></img>
                                ) : orderDetails?.status == 3 ? (
                                  <img src="assets/images/canceled.png"></img>
                                ) : (
                                  orderDetails?.status == 0 && (
                                    <img src="assets/images/orderPlaced.png"></img>
                                  )
                                )}
                                <p>{orderDetails?.orderDate}</p>
                                {/* //Ordered at Jan 2022 9:04 AM */}
                              </div>
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">Pricing </th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td class="product-subtotal thumb">
                                      <span class="subtotal-amount">
                                        Shipping
                                      </span>
                                    </td>
                                    <td class="product-subtotal thumb right">
                                      <span class="subtotal-amount ">Fees</span>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="product-subtotal thumb">
                                      <span class="subtotal-amount ">
                                        Pricing
                                      </span>
                                    </td>
                                    <td class="product-subtotal thumb right">
                                      <span class="subtotal-amount ">
                                        ₹ {orderDetails?.totalamt}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="product-subtotal thumb">
                                      <span class="subtotal-amount">
                                        Payment Mode
                                      </span>
                                    </td>
                                    <td class="product-subtotal thumb right">
                                      <span class="subtotal-amount ">
                                        {orderDetails?.payamentmethod}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="Shhping-add">
                                {console.log("--------------", orderDetails)}
                                <h1 className="heding">Shipping Address</h1>
                                <p class="para" style={{ fontWeight: "600" }}>
                                  {orderDetails?.name}
                                </p>
                                <p class="para">
                                  {orderDetails?.deliverAddress}
                                </p>
                              </div>

                              <div className="Shhping-add">
                                {console.log("--------------", orderDetails)}
                                <h1 className="heding">Delivery Slot</h1>
                                <p class="para" style={{ fontWeight: "600" }}>
                                  {orderDetails?.slote}
                                </p>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>

                <section class="cart-wraps-area  bootom-spacing ptb-bootom table-order">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <form>
                          <div class="">
                            <div class="cart-table table-responsive cart-pagess">
                              <table class="table table-bordered">
                                <thead>
                                  <h1 className="heding">Ordered Items</h1>
                                  <tr></tr>
                                </thead>

                                {orderDetails?.history?.items?.map(
                                  (i, index) => (
                                    <tbody>
                                      <tr>
                                        <td class="product-subtotal thumb">
                                          <span class="subtotal-amount">
                                            {i?.name}
                                          </span>
                                        </td>
                                        <td class="product-subtotal thumb">
                                          <span class="subtotal-amount qty">
                                            Qty: {i?.qty}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="product-subtotal thumb">
                                          <span class="subtotal-amount">
                                            ₹ {i?.amount}
                                            {/* <del>₹250 </del> */}
                                          </span>
                                        </td>
                                        <td class="product-subtotal thumb">
                                          {/* <span class="subtotal-amount">UPI</span> */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                )}
                              </table>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
    // }</>
  );
}

export default Profile;
