import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ApiModel from "../Utils/ApiModel";
import { Colors } from "../Utils/AppConstant";
import Loader from "../components/loader";

function Latestproduct() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const navigate = useNavigate()

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      get_latest_product();
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const get_latest_product = async () => {
    setIsLoading(true);
    ApiModel.sendApiCall(`product`, null, "GET", (response) => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
      if (response?.data?.status === 200) {
        setData(response?.data?.data);
      } else {
        setIsLoading(false);
        setData([]);
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="offer-area pt-100 pb-70 produtsss">
            <div className="container">
              <div className="section-title text-center">
                {/* <i className="flaticon-leaf"></i> */}
                <img
                  src="assets/images/Group-114.png"
                  className="vert-move"
                ></img>
                <h2>Latest Product</h2>
                <p>
                  Pedh Paudha is here to inspire you to have plants around you.
                  To look at life in a very different way, helping you connect
                  with Geography & Nature.
                </p>
              </div>
              <div className="row pt-45">
                {/* {data?.filter((j,index) => (index<3))?.map(i => {
                            return i?.productList?.items?.map((a, index) => {
                                return( */}

                {data.slice(0, 4).map((a, index) => {
                  return (
                    <div className="col-lg-3 col-sm-6 col-6">
                      <div className="offer-item ofer-items items-offff ">
                        <div className="offer-img">
                          <Link
                            to={{
                              pathname: "/productdetails",
                              state: { data: a },
                            }}
                          >
                            <img
                              loading="lazy"
                              style={{ cursor: "pointer", height: 185 }}
                              src={a?.image}
                              alt="Product Images"
                            />
                          </Link>
                          <div class="card__badge">
                            <span
                              class="badge badge--onsale"
                              aria-hidden="true"
                            >
                              SALE
                            </span>
                          </div>
                        </div>

                        <Link
                          to={{
                            pathname: "/productdetails",
                            state: { data: a },
                          }}
                        >
                          <div className="content">
                            <h3
                              className="headinfss"
                              style={{
                                fontSize: "medium",
                                color: Colors.textColor,
                              }}
                            >
                              <a href="productdetails">
                                {a?.name?.length > 21
                                  ? a?.name?.substring(0, 21) + "..."
                                  : a?.name}
                              </a>
                            </h3>
                            <div className="star">
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star-half"></i>
                              <span style={{ color: "red" }} className="rating">
                                4.8
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontSize: "medium",
                                  fontWeight: "bold",
                                  color: Colors.textColor,
                                }}
                              >
                                {"\u20B9" + a?.offerprice}
                              </h2>
                              <h3
                                style={{
                                  fontSize: "medium",
                                  fontWeight: "lighter",
                                  color: Colors.gray,
                                  textDecoration: "line-through",
                                  marginLeft: 5,
                                  marginBottom: "auto",
                                }}
                              >
                                {"\u20B9" + a?.price}
                              </h3>
                            </div>
                            {/* <button class="order-btn">Add to Cart</button> */}
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}

                {/* )
                            })
                        })} */}

                {/* <div className="col-lg-3 col-sm-6">
                            <div className="offer-item">
                                <div className="offer-img">
                                    <a href="#">
                                        <img src="assets/images/3592.png" alt="Product Images" />
                                    </a>
                                </div>
                                <div className="content">
                                    <h3><a href="/Productdetails">Plant Name</a></h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="offer-item">
                                <div className="offer-img">
                                    <a href="#">
                                        <img src="assets/images/3592.png" alt="Product Images" />
                                    </a>
                                </div>
                                <div className="content">
                                    <h3><a href="/Productdetails">Plant Name</a></h3>
                                    <p>
                                       Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="offer-item">
                                <div className="offer-img">
                                    <a href="/Productdetails">
                                        <img src="assets/images/3592.png" alt="Product Images" />
                                    </a>
                                </div>
                                <div className="content">
                                    <h3><a href="/Productdetails">Plant Name</a></h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="offer-item">
                                <div className="offer-img">
                                    <a href="#">
                                        <img src="assets/images/3592.png" alt="Product Images" />
                                    </a>
                                </div>
                                <div className="content">
                                    <h3><a href="/Productdetails">Plant Name</a></h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    </p>
                                </div>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>

          <div className="center">
            <a href="/product">
              <button class="order-btn">Explore More</button>
            </a>
          </div>
        </>
      )}
    </>
  );
}

export default Latestproduct;
