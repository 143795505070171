import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ApiModel from "../Utils/ApiModel";
import { Colors } from "../Utils/AppConstant";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast_func } from "../Utils/Toastify/toaster";
import axios from "axios";
import Loader from "../components/loader";

function Productdetails({ props }) {
  const location = useLocation().state.data;
  const [setFist, setImagFirst] = useState("");
  const catArray = useLocation().state.data?.catlog?.items;
  const [data, setData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [code, setCode] = useState("");
  const [checkCode, setCheckCode] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const value = window.localStorage.getItem("userdata");
  const userData = JSON.parse(value);
  const [reData, setReData] = useState("");
  const [wish, setWish] = useState([]);
  const [isWishlist, setIsWishlist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  {console.log('------------HHHHH GET VALUE', location)}

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      // get_latest_product();
      calculatePercent();
      // get_cart()
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  // const get_latest_product = async () => {
  //   // setIsLoading(true);
  //   ApiModel.sendApiCall(`catpro`, null, "GET", (response) => {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 1500);
  //     if (response?.data?.status === 200) {
  //       let data = response?.data?.category?.filter(
  //         (i) => i?.name === location?.catname
  //       );
  //       setData(data);
  //     } else {
  //       setIsLoading(false);
  //       setData([]);
  //     }
  //   });
  // };

  const get_cart = async () => {
    setIsLoading(true);
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `getaddtocart/${finalId?.userId}`, //change after user id get
      null,
      "GET",
      (result) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (result?.data?.status === 200) {
          setCartData(result?.data?.items);
          let a = result?.data?.items?.some(
            (i) => i?.productid === location?.id
          );
          setIsAvailable(a);
        } else {
          console.log("the else condition work in header section");
          setIsLoading(false);
        }
      },
      (error) => {
        console.log("the error is===>", error);
      }
    );
  };

  const calculatePercent = () => {
    let price = location?.price;
    let offerPrice = location?.offerprice;
    let discount = price - offerPrice;
    let percent = (100 * discount) / price;
    setPercentage(percent.toFixed(1));
  };

  const checkPincode = async () => {
    if (code?.length > 6) {
      toast_func("error", "Pincode is incorrect");
      setCheckCode(false);
    } else if (code?.length < 6) {
      toast_func("error", "Pincode is incorrect");
      setCheckCode(false);
    } else {
      setCheckCode(true);
    }
  };

  const addToCart = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    let data = JSON.stringify({
      id: (location?.id).toString(),
      img: location?.image,
      name: location?.name,
      stock: 0,
      offerPrice: location?.offerprice,
      actualPrice: location?.price,
      qty: 1,
      userid: finalId?.userId, //change after user registerd
    });
    ApiModel.sendApiCall(
      `addtocart`,
      data,
      null,
      (result) => {
        if (result?.data?.status === 200) {
          toast_func("success", result?.data?.success);
          setReData(result?.data);
          get_cart();
        } else {
          toast_func("warning", result?.data?.sussess);
        }
      },
      (error) => {
        console.log("the eror", error);
      }
    );
  };

  const goToCart = async () => {
    window.location.href = "/Cart";
  };

  const wishlistItems = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `getwishlist/${finalId?.userId}`,
      null,
      "GET",
      (result) => {
        // console.log('--------------isWishlist Result', result)
        if (result?.data?.status === 200) {
          setWish(result?.data?.items);
        } else {
          console.log("the else condition work in header section");
        }
      },
      (error) => {
        console.log("the error is===>", error);
      }
    );
  };

  useEffect(() => {
    wishlistItems();
  }, [reData]);

  const addToWishlist = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    // DeviceInfo.getUniqueId().then((uniqueId) => {
    let body = {
      id: location?.id,
      img: location?.image,
      name: location?.name,
      stock: 0,
      offerPrice: location?.offerprice,
      actualPrice: location?.price,
      qty: 1,
      userid: userData?.id,
      deviceId:
        userData?.id == "" || userData?.id != undefined ? null : res.data.IPv4, //currentUser != null ? null : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `wishlist`,
      body,
      null,
      (result) => {
        if (result?.data?.status === 200) {
          setReData(result?.data);
          wishlistItems();
          toast_func("success", result?.data?.success);
        } else {
          toast_func("warning", result?.data?.sussess);
        }
      },
      (error) => {
        console.log("the eror", error);
      }
    );
  };

  const checkWishlist = () => {
    let data = wish;
    let i = data.findIndex((item) => item?.productid == location?.id);
    if (i < 0) {
      setIsWishlist(false);
    } else {
      setIsWishlist(true);
    }
  };

  useEffect(() => {
    checkWishlist();
  }, [wish]); //reData

  // debugger

  return (
    <>
      <Header data={reData} />
      <ToastContainer />
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <section id="services" class="services section-bg  Productdetails">
              <div class="container">
                <div class="row row-sm">
                  <div class="col-md-6 _boxzoom">
                    <div class="zoom-thumb">
                      <ul
                        onClick={() => setImagFirst(location?.image)}
                        class="piclist"
                      >
                        <li>
                          <img src={location?.image} alt="" />
                        </li>
                      </ul>

                      {catArray.map((item, index) => (
                        <ul
                          onClick={() => setImagFirst(item?.image)}
                          class="piclist"
                        >
                          <li>
                            <img src={item?.image} alt="" />
                          </li>
                        </ul>
                      ))}
                    </div>

                    {setFist != "" ? (
                      <div class="_product-images">
                        {/* <div class="picZoomer"> */}
                        <img class="my_img" src={setFist} alt="" />
                        {/* </div> */}
                      </div>
                    ) : (
                      <div class="_product-images">
                        {/* <div class="picZoomer"> */}
                        <img class="my_img" src={location?.image} alt="" />
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                  <div class="col-md-6">
                    <div class="_product-detail-content">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p
                          class="_p-name"
                          style={{
                            color: Colors.textColor,
                            fontWeight: "bold",
                            fontSize: "x-large",
                          }}
                        >
                          {location?.name}
                        </p>
                        <span
                          style={{
                            fontWeight: "normal",
                            fontSize: 16,
                            color: Colors.white,
                            backgroundColor: Colors.backgroundColor,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 20,
                            paddingTop: 2,
                            alignItems: "center",
                            marginLeft: 10,
                            height: 31,
                            marginTop: 7,
                          }}
                          class="price"
                        >
                          {location?.catname}
                        </span>
                      </div>
                      <div class="star">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star-half"></i>
                        <span className="rating">4.8</span>
                      </div>
                      {/* <i class="bx bx-star starss"></i>
                           <i class="bx bx-star starss"></i><i class="bx bx-star starss"></i><i class="bx bx-star starss"></i> */}
                      <div class="_p-features">
                        <p
                          style={{
                            color: Colors.textColor,
                            fontWeight: "normal",
                            fontSize: "medium",
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              // location?.description.length > 130
                              //   ? location?.description.substring(0, 130) +
                              //     "..."
                              //   : 
                                location?.description,
                          }}
                        />
                      </div>
                      <div class="_p-price-box">
                        <div class="p-list">
                          {/* <span class="price"> ₹ 860 </span> */}

                          <div class="row">
                            <div class="price">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 20,
                                  color: Colors.textColor,
                                }}
                                class="unit"
                              >
                                {"\u20B9" + location?.offerprice}
                              </span>
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 20,
                                  color: Colors.gray,
                                }}
                                id="subtotal"
                                class="subtotal unit"
                              >
                                {"\u20B9" + location?.price}
                              </span>
                            </div>
                          </div>
                          {/* <span>  <del> ₹999  </del>   </span> */}
                          <span
                            style={{
                              fontWeight: "normal",
                              fontSize: 16,
                              color: Colors.white,
                              backgroundColor: Colors.backgroundColor,
                              paddingLeft: 10,
                              paddingTop: 2,
                              paddingRight: 10,
                              borderRadius: 20,
                            }}
                            class="price"
                          >{`${(percentage)}% off`}</span>
                        </div>
                      </div>

                      <h1
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: Colors.textColor,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <input
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            maxLength="7"
                            type="number"
                            placeholder="Check delivery"
                            name="pincode"
                            autoComplete
                            style={{
                              borderTopWidth: 0,
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              backgroundColor: "transparent",
                              borderBottomWidth: 1,
                              textAlign: "center",
                            }}
                          />
                          <span
                            onClick={() => checkPincode()}
                            style={{
                              marginLeft: 10,
                              marginTop: 10,
                              cursor: "pointer",
                            }}
                          >
                            <p>Check</p>
                          </span>
                        </div>
                        {/* Delivery Charge */}

                        <div
                          style={{
                            display: "flex",
                            marginTop: -10,
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <h3
                            style={{
                              color: Colors.backgroundColor,
                              color: "#384b5c",
                              fontSize: 14,
                              fontWeight: "normal",
                            }}
                          >
                            Delivery by Tommorow
                          </h3>
                          <h3
                            style={{
                              color: Colors.backgroundColor,
                              marginTop: 10,
                              fontSize: 14,
                              fontWeight: "normal",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {" "}
                            <h style={{ marginLeft: 5 }}>|</h>
                            <h3
                              style={{
                                fontSize: 14,
                                marginLeft: 5,
                                fontWeight: "normal",
                                marginRight: 10,
                                textDecorationLine: "line-through",
                                color: Colors.gray,
                              }}
                            >
                              {"\u20B9" + 40}
                            </h3>
                            Free Delivery
                          </h3>
                        </div>

                        {checkCode && (
                          <p
                            style={{
                              fontSize: 10,
                              color: Colors.backgroundColor,
                              fontWeight: "normal",
                            }}
                          >
                            Pincode is available for delivery.
                          </p>
                        )}
                      </h1>
                    </div>

                    <div class="_p-add-cart">
                      {/* <button class="btn-theme btn buy-btn" tabindex="0">
                           <i class="fa fa-shopping-cart"></i> Buy Now
                           </button> */}
                      <button
                        onClick={() => {
                          isAvailable ? goToCart() : addToCart();
                        }}
                        class="btn-theme btn btn-success"
                        tabIndex="0"
                      >
                        <i class="fa fa-shopping-cart"></i>
                        {isAvailable ? "View Cart" : "Add to Cart"}
                      </button>
                      {/* //isWishlist ? Icons.filled : Icons.unfilled */}
                      <button
                        onClick={() => addToWishlist()}
                        className="btn-widhlist"
                      >
                        {isWishlist ? (
                          <img
                            style={{ height: 30, width: 30, marginTop: -10 }}
                            src="assets/images/HeartFilled.png"
                          />
                        ) : (
                          <img
                            style={{ height: 30, width: 30, marginTop: -10 }}
                            src="assets/images/HeartUnfill.png"
                          />
                        )}
                      </button>
                    </div>

                    <div class="accordion" id="accordionPanelsStayOpenExample">
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingOne"
                        >
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            <img
                              style={{ height: 35, width: 35 }}
                              src="assets/images/watering-plants.png"
                            ></img>
                            <h style={{ color: "#0f2d52", marginLeft: 10 }}>
                              Water thrice A Week
                            </h>
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingOne"
                        >
                          <div class="accordion-body">
                            <p>
                              Always check your plants before watering, the
                              topsoil should be dry to touch. For succulents
                              allow the potting mix to dry completely before
                              watering again
                            </p>
                            <p>
                              Always water the plant thoroughly, that is, till
                              the water starts coming down from the bottom hole.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            <img
                              style={{ height: 35, width: 35 }}
                              src="assets/images/sunlight.png"
                            ></img>
                            <h style={{ color: "#0f2d52", marginLeft: 10 }}>
                              Brightness Indirect Sunlight
                            </h>
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div class="accordion-body">
                            <p>
                              Place your plants on window sills where it can get
                              the brightest possible indirect light. Bright
                              indirect light is when the plant is within a
                              couple of feet of a natural source of light.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingThree"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseThree"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseThree"
                          >
                            <img
                              style={{ height: 35, width: 35 }}
                              src="assets/images/pet.png"
                            ></img>
                            <h style={{ color: "#0f2d52", marginLeft: 10 }}>
                              Not Animal Friendly
                            </h>
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingThree"
                        >
                          <div class="accordion-body">
                            <p>
                              This plant and your furry friends cannot become
                              the best buds.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingFour"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseFour"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseFour"
                          >
                            <img
                              style={{ height: 35, width: 35 }}
                              src="assets/images/grow.png"
                            ></img>
                            <h style={{ color: "#0f2d52", marginLeft: 10 }}>
                              Beginner Friendly
                            </h>
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingFour"
                        >
                          <div class="accordion-body">
                            <p>
                              If you are a new gardener this is a great choice
                              for you. The plant will thrive in your journey to
                              learn gardening with trials and errors.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="about-produ">
              <div class="container ">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <div className=" about-pro">
                      <img src="assets/images/whatsIn.jpeg"></img>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <h3>What’s in the Box</h3>
                    <p>
                      {location?.name} is a plant with a lot of different
                      varieties. The most popular of this variety has pointed
                      green leaves with yellow edges.<br></br>
                      {location?.name} are perfect tabletop and{" "}
                      {location?.catname}.<br></br>
                      It’s a low-maintenance plant and is perfect for new
                      gardeners.{" "}
                    </p>
                    <ul>
                      <li>{location?.name} with Pot: 23 - 28 cm</li>
                      <li>Pot Size: 4 inch diameter</li>
                      <li>Soil Media:Coco Peat+Coco Chips+Soil</li>
                      <li>Recyclable box</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <div class="offer-area pt-100 pb-70">
              <div class="container text-center">
                <div class="section-title text-center">
                  <h2>Products</h2>
                  <p class="paragraph">
                    Nature is the ultimate source of our living. Both living and
                    non-living things include nature, and everyone is
                    interdependent, which helps maintain the ecosystem. Plants,
                    animals, and humans all depend on nature for their survival.{" "}
                  </p>
                </div>

                {/* <div class="row pt-45 justify-content-center sec-pro">
                  <h1 class="pr0">Related Products</h1>
                  
                  
                  

                     {data?.map(i => {
                        return i?.productList?.items?.map((a) => {
                           return(
                              <div
                                 class="col-lg-3  col-sm-6">
                              <div class="product-item">
                                 <div class="product-img"><img src={a?.image} alt="Product Images"/></div>
                                 <div class="content">
                                 <h3 style={{fontSize:'medium', color:Colors.textColor}}><a href="productdetails"
                                    // onClick={() => navigate('/Productdetails')}
                                    >
                                    {a?.name?.length > 21 ? a?.name?.substring(0,21) + "..." : a?.name}</a></h3>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                          <h2 style={{fontSize:'medium',fontWeight:'bold', color:Colors.textColor}}>{"\u20B9" + a?.offerprice}</h2>
                                          <h3 style={{fontSize:'medium',fontWeight:'lighter',color:Colors.gray, textDecoration:'line-through', marginLeft:5,marginBottom:'auto'}}>{"\u20B9" + a?.price}</h3>
                                    </div>
                                 </div>
                              </div>

                              
                              </div>
                        )
                        })
                     })}


                     

                     
                  </div> */}
              </div>
              <div></div>
            </div>
          </>
        )}
      </>

      <Footer />
    </>
  );
}

export default Productdetails;
