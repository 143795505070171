import React, { useEffect, useMemo, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ApiModel from "../Utils/ApiModel";
function Testimonial() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [data, setData] = useState([]);

  //   useEffect(()=>{
  //     get_user_review()
  // },[])

  //   const get_user_review = async() => {
  //       ApiModel.sendApiCall(
  //           `testimonial`,
  //           null,
  //           'GET',
  //           (response) => {
  //               if(response?.data?.status === 200){
  //                   setData(response?.data?.data)
  //               }else{
  //                   setData([])
  //               }
  //           },
  //           (error) => {
  //               console.log('the error is==>', error)
  //           }
  //       )
  //   }

  return (
    <>
      <section className="test">
        <div
          id="carouselExampleDark"
          class="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <div class="carousel-caption d-none d-md-block">
                <i class="bx bxs-quote-alt-right icon"></i>
                <div className="img">
                  <img src="assets/images/Mask.png"></img>
                </div>

                <h5>Dennis Patrick</h5>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                {/* <i class='bx bx-star starss'></i>
      <i class='bx bx-star starss'></i>
      <i class='bx bx-star starss'></i>
      <i class='bx bx-star starss' ></i> */}
                <p>
                  Some representative placeholder content for the first slide.
                </p>
              </div>
            </div>

            <div class="carousel-item">
              <div class="carousel-caption d-none d-md-block">
                <i class="bx bxs-quote-alt-right icon"></i>
                <div className="img">
                  <img src="assets/images/newsletter-img.jpg"></img>
                </div>

                <h5>Chandan Singh</h5>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <p>
                  Some representative placeholder content for the third slide.
                </p>
              </div>
            </div>

            <div class="carousel-item">
              <div class="carousel-caption d-none d-md-block">
                <i class="bx bxs-quote-alt-right icon"></i>
                <div className="img">
                  <img src="assets/images/newsletter-img.jpg"></img>
                </div>

                <h5>Saddam</h5>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <img src="assets/images/star.gif" class="starts"></img>
                <p>
                  Some representative placeholder content for the third slide.
                </p>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div className="logo-imgses">
          <img src="assets/images/1st.png" className="vert-move"></img>
        </div>

        <div className="logo-imgsess">
          <img src="assets/images/2nd.png" className="vert-move"></img>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
