import React, { useEffect, useMemo, useState } from "react";
import ApiModel from "../Utils/ApiModel";
import { Colors } from "../Utils/AppConstant";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";

function Header({data, pro}){
    const [cart_length, setCartLength] = useState(0)
    const value = window.localStorage.getItem('userdata');
    const userData = JSON.parse(value);
    const [update, setUpdate] = useState('')
    const location = useLocation()
    window.scrollTo(0, 0)



    const get_cart = async() => {
        const res = await axios.get('https://geolocation-db.com/json/')
        let finalId ={
        userId:  userData?.id == '' || userData?.id != undefined ? userData?.id : res.data.IPv4
        }
        ApiModel.sendApiCall(
            `getaddtocart/${finalId?.userId}`,
            null,
            'GET',
            (result) => {
               
                if(result?.data?.status === 200){
                    let leng = result?.data?.items?.length
                    setCartLength(leng)
                }else{
                    console.log('the else condition work in header section')
                }
            },
            (error) => {
                console.log('the error is===>', error)
            }
        )
    }



    useMemo(()=>{
        if (data?.success == 'Item Add Successful!' &&  pro == 'profile') {
            get_cart() 
        }else{
            get_cart() 
        }
    },[data]) //cart_length, props


    return(
        <>
      

<header className="header ">
    
<nav class="navbar navbar-expand-lg navbar-light bg-light shadow ">
    <div class="container">
        <div style={{width:134}}>
      <a class="navbar-brand"  href="/"> <img loading="lazy" className="logo-one logo" style={{/*height:100,*/ width:100}} src="assets/images/logos/logo.png" alt="Logo"/> </a>
      </div>
      <div class="side-nav-responsive" 
>
<div class="container">
<div class="dot-menu">
<div class="circle-inner">
 <li class="nav-item cart-s">
            <a class="nav-link" href="/Cart"> <img src="assets/images/wired.gif"></img> </a>
            <span style={{fontSize:12}}>{cart_length}</span>
          </li>
</div>
</div>
<div class="container-3">
<div class="side-nav-inner">
<div class="side-nav justify-content-center align-items-center">
<div class="side-nav-item">
<div class="language-on-list">
<select class="language-list-item">
<option>English</option>
</select>
</div>
<div class="side-nav-cart">
<a href="#"><i class="bx bx-cart"></i></a>
<span>1</span>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content">
        <div class="hamburger-toggle">
          <div class="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </button>
      <div class="collapse navbar-collapse" id="navbar-content">
        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
          <li style={{position:'relative'}} class="nav-item">
            <a style={{color: location?.pathname === '/' ? '#84B441' : '#2e3b5d'}} class="nav-link" aria-current="/" href="/">Home</a>
            <div className="line" style={{background: location?.pathname === '/' ? '#84b440' : null, width: 12, marginTop:-10, borderRadius:20, height: 3, position: 'absolute', left: '50%',}}></div>
          </li>
       
          <li style={{position:'relative'}} class="nav-item">
            <a style={{color: location?.pathname === '/product' ? '#84B441' : '#2e3b5d'}} class="nav-link" href="/product">Products</a>
            <div className="line" style={{background: location?.pathname === '/product' ? '#84b440' : null, width: 12, marginTop:-10, borderRadius:20, height: 3, position: 'absolute', left: '50%',}}></div>
          </li>

          <li style={{position:'relative'}} class="nav-item">
            <a style={{color: location?.pathname === '/Blog' ? '#84B441' : '#2e3b5d'}} class="nav-link" href="/Blog">Blog</a> 
            <div className="line" style={{background: location?.pathname === '/Blog' ? '#84b440' : null, width: 12, marginTop:-10, borderRadius:20, height: 3, position: 'absolute', left: '50%',}}></div>
          </li>
  
          <li style={{position:'relative'}} class="nav-item">
            <a style={{color: location?.pathname === '/AboutUs' ? '#84B441' : '#2e3b5d'}} class="nav-link" href="/About-Us"> AboutUs</a>
            <div className="line" style={{background: location?.pathname === '/AboutUs' ? '#84b440' : null, width: 12, marginTop:-10, borderRadius:20, height: 3, position: 'absolute', left: '50%',}}></div>
          </li>

          <li style={{position:'relative'}} class="nav-item">
            <a style={{color: location?.pathname === '/ContactUs' ? '#84B441' : '#2e3b5d'}} class="nav-link" href="/Contact-Us"> ContactUs</a>
            <div className="line" style={{background: location?.pathname === '/ContactUs' ? '#84b440' : null, width: 12, marginTop:-10, borderRadius:20, height: 3, position: 'absolute', left: '50%',}}></div>
          </li>

          {userData?.id == '' || userData?.id == undefined && 
          <li class="nav-item">
            <a class="nav-link login" href="/login">  Login </a>
          </li>
        }


          <li class="nav-item cart-s">
            <a class="nav-link" href="/Cart"> <img src="assets/images/wired.gif"></img> </a>
            <span style={{fontSize:12}}>{cart_length}</span>
          </li>


         {userData?.id == '' || userData?.id != undefined && 
          <li class="nav-item cart-s">
            <a class="nav-link" href="/Profile"><img src="assets/images/wir-1.gif"></img></a>
          </li>
        }

                        {/* {userData?.id == '' || userData?.id != undefined && 
                            <li className="nav-item item-nvs ">
                                <a href="/Profile" className="">
                                <i class='bx bx-user'></i>
                                </a>
                            </li>
                        } */}
       
        </ul>
     
      </div>
    </div>
  </nav>

  </header>
    {/* <div className="navbar-area">
        <div className="mobile-nav mean-container">
            <a href="index.html" className="logo">
                <img loading="lazy" className="logo-one" style={{height:100, width:100}} src="assets/images/logos/logo.png" alt="Logo"/>
            </a>
        </div>

        <div className="main-nav nav-three">
            <div className="container">
                <nav className="navbar navbar-expand-md navbar-light ">
                    <a className="navbar-brand title" href="/">
                        <img src="assets/images/logos/logo.png" loading="lazy" className="logo-one" alt="Logo"/>
                        <img src="assets/images/logos/logo.png" loading="lazy" className="logo-two" alt="Logo"/>
                    </a>
                    <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item ">
                                <a href="/" className="nav-link active">
                                    Home
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="/product" className="nav-link">
                                    Products
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/headerBlog" className="nav-link">
                                    Blog
                                </a>

                            </li>
                            <li className="nav-item">
                                <a href="/about" className="nav-link">
                                    About Us
                                </a>
                            </li>
                          
                            <li className="nav-item contact">
                                <a href="/contact" className="nav-link contact">
                                    Contact
                                </a>
                            </li>
                            {userData?.id == '' || userData?.id == undefined && 
                                <li className="nav-item contact login">
                                    <a href="/Login" className="nav-link contact">
                                        Login
                                    </a>
                                </li>       
                            }
                               <li className="nav-item" style={{display:"flex", alignItems:'center'}}>
                                <a style={{display:"flex", alignItems:'center'}} href="/Cart" className="nav-link">
                                    <h6 style={{
                                        position:'absolute', 
                                        backgroundColor:Colors.backgroundColor, 
                                        color:Colors.textColor,
                                        borderRadius:10,
                                        paddingLeft:5,
                                        paddingRight:5,
                                        bottom:18,
                                        right:20,
                                        fontSize:'small',
                                        fontWeight:'normal'
                                    }}>{cart_length}</h6>
                                <i class='bx bx-cart'></i>
                                </a>
                            </li>

                        {userData?.id == '' || userData?.id != undefined && 
                            <li className="nav-item item-nvs ">
                                <a href="/Profile" className="">
                                <i class='bx bx-user'></i>
                                </a>
                                <ul class="dropdown-menu">
                                {/* <li class="nav-item">
                                <a href="blog-1.html" class="nav-link">
                                Login
                                </a>
                                </li> 
                            </li>
                        }
                        </ul>

          
                       
                    </div>
                </nav>
            </div>
        </div>
        
    </div> */}

        </>
    )
}

export default Header;
