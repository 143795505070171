import axios from "axios";

let baseUrl = "https://gazingtechnosoft.net/swati/gazingnursery/api/";

export default {
  sendApiCall: async (
    url,
    payload,
    method,
    successHandler,
    ErrorHandler,
    formDatas,
  ) => {
    method = method ? method : "POST";
    var token = await localStorage.getItem("token");
    var headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
    }

    if (formDatas) {
      var headers = {
        "Content-Type": "multipart/form-data",
      };
    }
    var requestBody = {
      method: method,
      headers: headers,
    };
    if (method === "POST") {
      requestBody.data = payload;
    }
   await axios(baseUrl + url, requestBody)
    .then((response)=>{
        if(response.status === 200 || response.status === 201 || response.status === 'ok'){
            successHandler(response)
        }else{
            ErrorHandler(response)
        }
    })
    .catch((error)=>{
        ErrorHandler(error)
        console.log('the catch error is ===>',error)
    })
  },
};




// FOR PATCH API


// import axios from "axios";
// let baseUrl = "https://gazingtechnosoft.net/swati/gazingnursery/api/";

// export default {
//   sendApiCall: async (
//     url,
//     payload,
//     method,
//     successHandler,
//     errorHandler,
//     formDatas
//   ) => {
//     method = method ? method.toUpperCase() : "POST"; // Convert the method to uppercase

//     var token = await localStorage.getItem("token");
//     var headers = {
//       "Content-Type": "application/json",
//     };
//     if (token) {
//       headers.Authorization = "Bearer " + token; // Add authorization header
//     }

//     if (formDatas) {
//       headers["Content-Type"] = "multipart/form-data"; // Set content type for form data
//     }

//     var requestBody = {
//       method: method,
//       headers: headers,
//     };

//     if (method === "POST" || method === "PATCH") { // Handle PATCH method
//       requestBody.data = payload;
//     }

//     await axios(baseUrl + url, requestBody)
//       .then((response) => {
//         if (
//           response.status === 200 ||
//           response.status === 201 ||
//           response.status === "ok"
//         ) {
//           successHandler(response);
//         } else {
//           errorHandler(response);
//         }
//       })
//       .catch((error) => {
//         errorHandler(error);
//         console.log("The catch error is ===>", error);
//       });
//   },
// };