import React from "react";
function AboutUspage() {
  return (
    <>
      <section className="about">
        <div class="why-choose-two__shape-1 img-bounce">
          <img src="assets/images/thumbnail.png" alt="" />
        </div>
        <div className="about-area pt-45">
          <div className="container pt-100 ">
            <div className="section-title text-center area-about">
              <h2>About us</h2>
              <p>
                It is our mission to encourage people to discover beauty,
                miracles, and serendipity in their everyday lives. Our mission
                is to make the magic of plants available to you at your
                fingertips, and to safeguard and nurture them until they reach
                you - their forever homes. With our ideas, knowledge, and
                understanding, we make plant parenting easier, more comfortable,
                and less intimidating. Our plants are grown with passion,
                happiness, and utmost care so that they spread the same joy to
                you when they reach you.
              </p>
              <p>
                We are passionate about promoting a slower way of life and
                helping you to find the small joys in life. Over 25+ acres of
                land, we grow our own plants in state-of-the-art playhouses and
                greenhouses. We provide expert guidance and assistance from
                planting to transporting the plants to your home. Using a
                science-based approach, adhering to sustainable practices, and
                growing a community of growers and business owners, we grow with
                you.
              </p>
            </div>
          </div>
        </div>

        <div className="center-img">
          <a href="#">
            <img
              style={{}}
              loading="lazy"
              src="assets/images/PlantDes.jpg"
              alt="Images"
            />
          </a>
        </div>
      </section>
    </>
  );
}

export default AboutUspage;
