import React, { useEffect, useMemo, useState } from "react";
import ApiModel from "../Utils/ApiModel";
import { Link } from "react-router-dom";
import { Colors } from "../Utils/AppConstant";

function BlogComponent() {
  const [data, setData] = useState([]);

  const get_blogs = async () => {
    ApiModel.sendApiCall(
      `blog`,
      null,
      "GET",
      (result) => {
        if (result?.data?.status === 200) {
          setData(result?.data?.data);
        } else {
          setData([]);
        }
      },
      (error) => {
        console.log("the error in get blog component===>", error);
      }
    );
  };

  useEffect(() => {
    get_blogs();
  }, []);

  return (
    <>
      <section className="blog-area pt-100 pb-70">
        <div className="container text-center">
          <div className="section-title text-center">
            <h2>Our Blogs</h2>
            <p>
              The blogs are available to learn how plant is more important in
              our life. How we can manage the plants to grow bigger.
            </p>
          </div>

          <div class="row pt-45 justify-content-center">
            {data?.map((object, index) => (
              <div class="col-lg-3 col-md-4 col-6">
                <div class="blog-card blog-color-2">
                  <a href="#">
                    <img loading="lazy" src={object.image} alt="Images" />
                  </a>
                  <div class="content">
                    <p style={{ color: Colors.textColor, fontWeight: "bold" }}>
                      {/* {object.title} */}
                      {object.title.length > 50
                        ? object.title.substring(0, 50) + "..."
                        : object.title}
                    </p>
                    <Link
                      className="link-h"
                      style={{ fontWeight: "bold", fontSize: 14 }}
                      to={{
                        pathname: "/blogs",
                        state: { data: object },
                      }}
                    >
                      Read More
                    </Link>
                    {/* <a href="/blogs" class="read-btn">Read More</a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button className="order-btn">Load More</button>
        </div>
      </section>
    </>
  );
}

export default BlogComponent;
