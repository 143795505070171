import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { toast_func } from "../Utils/Toastify/toaster";
import ApiModel from "../Utils/ApiModel";
import { ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

function Register() {
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();
  const navigateTo = () => history.push("/Login");

  const handleLogin = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    if (name != "") {
      if (emailId != "") {
        if (phone != "") {
          if (password != "") {
            if (password == confirmPassword) {
              console.log("-------HIT SUC", password == confirmPassword);
              let body = {
                name: name,
                email: emailId,
                phone: phone,
                password: password,
                deviceId: res.data.IPv4,
                // type:checkType
              };
              ApiModel.sendApiCall(
                `registration`,
                body,
                null,
                (result) => {
                  if (result?.data?.status === 200) {
                    navigateTo();
                    alert("Registration successfull!");
                    toast_func("success", "Registration successfull!");
                  } else {
                    console.log("----------hit elseeee");
                    // alert('User already registered with this credentials')
                    toast_func(
                      "warning",
                      "User already registered with this credentials"
                    );
                  }
                },
                (error) => {
                  console.log("the error is ===>", error);
                }
              );
            } else {
              toast_func(
                "warning",
                "Password and confirm password should match"
              );
            }
          } else {
            toast_func("warning", "Please enter your password");
          }
        } else {
          toast_func("warning", "Please enter your phone");
        }
      } else {
        toast_func("warning", "Please enter your emailId");
      }
    } else {
      toast_func("warning", "Please enter your name");
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <section className="login register">
        <div class="container">
          <div class="row">
            <div className="video-sec">
              <video
                src="video/registers.mp4"
                type="video/mp4"
                poster="Final Render_PosterImage.png"
                width="100%"
                muted
                loop
                autoPlay
              ></video>
            </div>

            <div class="user-area  login-patrs">
              <div class="user-width">
                <div class="user-form">
                  <div class="contact-form">
                    {/* <div class="logo-center">
<a class="navbar-brand title">

    <img src="assets/images/logos/logo.png" class="logo-login" loading="lazy" alt="Logo"/>
</a>
</div> */}
                    {/* <form> */}
                    <div class="row">
                      <h1>Create An Account</h1>
                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            class="form-control"
                            data-error="Please enter your name"
                            placeholder="Please enter your name"
                            required
                          />
                          <i class="bx bx-user"></i>
                        </div>
                      </div>
                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <input
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                            type="text"
                            class="form-control"
                            data-error="Please enter your emailId"
                            placeholder="Please enter your emailid"
                            required
                          />
                          <i class="bx bx-mobile"></i>
                        </div>
                        <div class="form-group">
                          <input
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                            class="form-control"
                            data-error="Please enter your phone"
                            placeholder="Please enter your phone"
                            required
                          />
                          <i class="bx bx-envelope"></i>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            class="form-control"
                            type="password"
                            name="password"
                            placeholder="Please enter your password"
                            required
                          />
                          <i class="bx bx-low-vision"></i>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <input
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            class="form-control"
                            type="password"
                            name="password"
                            placeholder="Please enter your confirm password"
                            required
                          />
                          <i class="bx bx-low-vision"></i>
                        </div>
                      </div>
                      <div class="col-lg-12 form-condition">
                        {/* <div class="agree-label">
<input type="checkbox" id="chb1"/>
<label for="chb1">
I agree Tserm of the Services
</label>
</div> */}
                      </div>
                      <div class="col-lg-12 ">
                        <button
                          onClick={() => handleLogin()}
                          type="submit"
                          class="default-btn btn-bg-three btn-sign"
                        >
                          Continue
                        </button>
                        <p>
                          Already have an account
                          <a
                            href="/Login"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            className="login-s"
                          >
                            Login
                          </a>
                        </p>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Register;
