import React, { useEffect, useMemo, useState } from "react";
import ApiModel from "../Utils/ApiModel";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "../components/loader";

function PrivacyPolicy() {
  const [privacy, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const get_privacy = async () => {
    setIsLoading(true);
    ApiModel.sendApiCall(
      `termsandconditions`,
      null,
      "GET",
      (response) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (response?.data?.status === 200) {
          setData(response?.data?.result);
        } else {
          setData([]);
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error in blog page is===>", error);
      }
    );
  };
  useEffect(() => {
    get_privacy();
  }, []);

  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <section className="privacy-ploicy">
          <div
            dangerouslySetInnerHTML={{ __html: privacy }}
            className="container privacy-polcy"
          ></div>
        </section>
      )}
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
