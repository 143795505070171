import React, { useCallback, useMemo, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import ApiModel from "../Utils/ApiModel";
import { ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { toast_func } from "../Utils/Toastify/toaster";
import axios from "axios";
import useRazorpay from "react-razorpay";
import Loader from "../components/loader";
import Modal from "react-modal" 
import { ConsoleView } from "react-device-detect";

function Cartdetails(props) {
  const Razorpay = useRazorpay();
  const cartProductAmt = useLocation().state?.amount;
  const cartProduct = useLocation().state?.data;
  const [timeSlot, setSlot] = useState([]);
  const [address, setData] = useState([]);
  const [slotID, setSlotID] = useState(5);
  const [udated, setUpdated] = useState("");
  const value = window.localStorage.getItem("userdata");
  const userData = JSON.parse(value);
  const history = useHistory();
  const navigateTo = () => history.push("/login");
  const navigateToPro = () => history.push("/profile");
  const navigateToHome = () => history.push("/OrderSuccessfully");
  const uAddress = window.localStorage.getItem("userAddress");
  const userAdd = JSON.parse(uAddress);
  const [isSelected, setSelection] = useState(true);
  const [isSelected1, setSelection1] = useState(false);
  const [payment, setPayment] = useState("Online");
  const [isLoading, setIsLoading] = useState(false);

  const [wishlist, setWishlist] = useState([]);
  const [profile, setProfile] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [street, setStreet] = useState("");
  const [landmark, setLandmark] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [reuse, setReuse] = useState([]);
  const [close, setClose] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userGuest, setUserGuest] = useState('')
  const [leftAmt, setLeftAmt] = useState(499)
  const [deliveryCharge, setDeliveryCharge] = useState(49);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: 0,
      transform: "translate(-50%, -50%)",
      width: 500,
    },
  };


  const paymentSelection = (mode) => {
    setPayment(mode);
    if (mode === "Online") {
      setSelection(true);
      setSelection1(false);
      console.log("HIT ONLINE");
    } else {
      setSelection1(true);
      setSelection(false);
      console.log("HIT COD");
    }
  };


  const createOrder = (amt) => {
    if (udated != "") {
      if (payment === "Online") {
        const options = {
          key: "rzp_test_RhF2pDPaBaNK9K", // Enter the Key ID generated from the Dashboard
          amount: amt * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Gazing Technosoft Pvt Ltd",
          description: "Test Transaction",
          image: "https://i.imgur.com/3g7nmJC.png",
          // order_id: "order_9A33XWu170gUtm98", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {
            if (response?.razorpay_payment_id != "") {
              let body = {
                name: userData?.name,
                payamentmethod: payment,
                addressid: udated?.id,
                userid: userData?.id,
                couponcode: "",
                timeslotid: slotID,
                totaldiscount: "20",
                order: cartProduct,
                totalAmount: leftAmt - cartProductAmt?.amt > 0 ? cartProductAmt?.amt+deliveryCharge : cartProductAmt?.amt //cartProductAmt?.amt,
              };
              if (!udated) {
                toast_func("warning", "Please Add Delivery Address");
              } else {
                ApiModel.sendApiCall(
                  `order`,
                  body,
                  null,
                  (result) => {
                    console.log("------------result ONLINE", result);
                    if (result?.data?.status === 200) {
                      toast_func("success", "Order successfully placed!");
                      navigateToHome();
                    } else {
                      console.log("te error is==>");
                    }
                  },
                  (error) => {
                    console.log("the error is ===>", error);
                  }
                );
              }
            }
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
          },
          prefill: {
            name: userData?.name,
            email: userData?.email,
            contact: userData?.phone,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });
        rzp1.open();
      } else {
        let body = {
          name: userData?.name,
          payamentmethod: payment,
          addressid: udated?.id,
          userid: userData?.id,
          couponcode: "",
          timeslotid: slotID,
          totaldiscount: "20",
          order: cartProduct,
          totalAmount: leftAmt - cartProductAmt?.amt > 0 ? cartProductAmt?.amt+deliveryCharge : cartProductAmt?.amt //cartProductAmt?.amt,
        };
        if (!address) {
          toast_func("warning", "Please Add Delivery Address");
        } else {
          ApiModel.sendApiCall(
            `order`,
            body,
            null,
            (result) => {
              console.log("------------result COD", result);
              if (result?.data?.status === 200) {
                toast_func("success", "Order successfully placed!");
                navigateToHome();
              } else {
                console.log("te error is==>");
              }
            },
            (error) => {
              console.log("the error is ===>", error);
            }
          );
        }
      }
    } else {
      toast_func("warning", "Please select delivery address");
    }
  };

  const addAdrress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    if (name === "") {
      alert("Please enter your full name");
    } else if (mobile === "") {
      alert("Please enter your valid mobile number");
    } else if (street === "") {
      alert("Please enter your valid email id");
    } else if (landmark === "") {
      alert("Please enter your landmark");
    } else if (state === "") {
      alert("Please enter your state name");
    } else if (pinCode === "") {
      alert("Please enter your pincode");
    } else {
      let body = {
        name: name,
        phone: mobile,
        state: state,
        pincode: pinCode,
        city: street,
        address: landmark,
        userid: finalId?.userId,
      };
      ApiModel.sendApiCall(
        `addressadd`,
        body,
        null,
        (result) => {
          if (result?.status === 200) {
            setClose(true);
            setReuse(result);
            get_address();
            toast_func("success", "Address added successfully!");
            setName("");
            setMobile("");
            setLandmark("");
            setPinCode("");
            setStreet("");
            setState("");
          } else {
            console.log("te error");
          }
        },
        (error) => {
          console.log("the error is ===>", error);
        }
      );
    }
  };

  const get_slot = async () => {
    setIsLoading(true);
    ApiModel.sendApiCall(
      `timeslot`,
      null,
      "GET",
      (result) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (result?.data?.status === 200) {
          setSlot(result?.data?.data);
        } else {
          setIsLoading(false);
          console.log("the else condition work in cart section");
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error is===iii>", error);
      }
    );
  };

  const get_address = async () => {
    setIsLoading(true);
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `getaddtess/${finalId?.userId}`,
      null,
      "GET",
      (response) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (response?.data?.status === 200) {
          setData(response?.data?.result);
        } else {
          setIsLoading(false);
          setData([]);
          toast_func("Fuction is not working");
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  useMemo(() => {
    get_slot();
    get_address();
  }, []);

  const onTimeCheck = (item) => {
    setSlotID(item);
  };

  const handleLogin = () => {
    toast_func("warning", "Login required!!");
    navigateTo();
  };

  const guestCheckout = () =>{
    setUserGuest('guest')
    setModalOpen(false)
  }

  const onUpdateAdd = (i) => {
    setUpdated(i);
    localStorage.setItem("userAddress", JSON.stringify(i));
  };



  const chooseLogin = () => {
    setModalOpen(true);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />
          <Header />

          <section class="product-deatial ">
            <div className="container">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <h1>Product Details</h1>
                  <table class="table table-bordered">
                    <tbody>
                      <div class="cart-table order-table order-table-2">
                        {cartProduct?.map((item, index) => {
                          return (
                            <div class="table-responsive">
                              <table class="table mb-0">
                                <tbody>
                                  <tr>
                                    <td class="product-detail">
                                      <div class="product border-0">
                                        <a
                                          href="product.left-sidebar.html"
                                          class="product-image"
                                        >
                                          <img
                                            src={item?.img}
                                            class="img-fluid blur-up lazyloaded"
                                            alt=""
                                          />
                                        </a>
                                        <div
                                          style={{ marginTop: -20 }}
                                          class="product-detail"
                                        >
                                          <ul>
                                            <li
                                              style={{ fontSize: 20 }}
                                              class="text-content price"
                                            >
                                              {item?.name}
                                            </li>
                                            <li
                                              style={{ fontSize: 18 }}
                                              class="text-content price item"
                                            >
                                              Quantity: {item?.qty}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="subtotal sub-total">
                                      <h4
                                        style={{ marginTop: 12 }}
                                        class="table-title text-content conttt"
                                      >
                                        Total Amount.
                                      </h4>
                                      <p className="pricess">
                                        ₹{item?.offerPrice}
                                        <span>
                                          <del style={{ marginLeft: 5 }}>
                                            ₹{item?.actualPrice}
                                          </del>
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        })}
                      </div>
                    </tbody>
                  </table>
                </div>

                {udated != "" && userAdd != null ? (
                  // userData?.id == '' && userData?.id != undefined ?
                  <div className="col-md-8 mx-auto">
                    <div class="mis-use">
                      <div class="radio">
                        <label for="radio-1" class="radio-label">
                          <span class="title-sec">{udated?.name}</span>
                        </label>
                      </div>
                      <p>
                        {udated?.city}, {udated?.address}, {udated?.state},{" "}
                        {udated?.pincode}
                      </p>
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalss"
                        class="read-btn"
                      >
                        Change{" "}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-8 mx-auto">
                    <button
                      className="default-btn btn-bg-three btn-sec"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalss"
                    >
                      Add Delivery Address{" "}
                    </button>
                  </div>
                )}

                <div class="col-lg-8 mx-auto">
                  <div className="mis-use">
                    <div class="row">
                      {timeSlot?.map((item, index) => {
                        return (
                          <div class="col-md-2 col-4">
                            <button
                              style={{
                                backgroundColor:
                                  slotID == item?.id ? "#9bc265" : "#ffff",
                                borderColor:
                                  slotID == item?.id ? "#ffffff" : "#9bc265",
                                color: slotID == item?.id ? "#ffff" : "#9bc265",
                              }}
                              onClick={() => onTimeCheck(item?.id)}
                              class="list-group-item list-group-item-action "
                            >
                              {item?.timeslot}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 mx-auto">
                  <div class="cart-totals">
                    <h3>Select Method</h3>
                    <div className="row">
                      <div class="col-lg-6 ">
                        <div class="full0-imagess bottom ">
                          <img src="assets/images/pngegg.png"></img>
                        </div>
                      </div>

                      <div class="col-lg-6 ">
                        <div
                          onClick={() => paymentSelection("Online")}
                          class="radio right-sec "
                        >
                          <input
                            id="radio-1"
                            name="radio"
                            type="radio"
                            checked
                          />
                          <label for="radio-1" class="radio-label"></label>
                        </div>
                      </div>

                      <div class="col-lg-6 ">
                        <div class="full0-imagess cash">
                          <img src="assets/images/PngItem_1827844.png"></img>
                          <span>Cash on Delivery</span>
                        </div>
                      </div>

                      <div class="col-lg-6 ">
                        <div
                          onClick={() => paymentSelection("COD")}
                          class="radio right-sec"
                        >
                          <input id="radio-2" name="radio" type="radio" />
                          <label for="radio-2" class="radio-label"></label>
                        </div>
                      </div>
                    </div>
                    <ul className="list-border">
                      <li>
                        Amount <span>₹{cartProductAmt?.amt}.00</span>
                      </li>
                      <li>
                        Taxes <span>₹{cartProductAmt?.taxes}.00</span>
                      </li>
                      {/* {console.log('---------GET DELIVERY CHARGE', leftAmt ,  cartProductAmt?.amt , cartProductAmt?.delivery)} */}
                      <li>
                        Delivery Charge{" "}
                        <span>₹{leftAmt - cartProductAmt?.amt > 0 ? cartProductAmt?.delivery : 0}.00</span>
                      </li>
                      <li>
                        Discount <span>₹{cartProductAmt?.discounts}.00</span>
                      </li>
                      <li className="total">
                        Total{" "}
                        <span>
                          <b>₹{leftAmt - cartProductAmt?.amt > 0 ? cartProductAmt?.amt+deliveryCharge : cartProductAmt?.amt}.00</b>
                        </span>
                      </li>
                    </ul>
                    <div className="btn-r">
                      {console.log('------------GETTING VALUE FOR FINAL', leftAmt - cartProductAmt?.amt > 0 ? cartProductAmt?.amt+deliveryCharge : cartProductAmt?.amt)}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          userData?.id == "" || userData?.id != undefined || userGuest === 'guest'
                            ? // handlePayment(cartProductAmt?.amt)
                              createOrder(leftAmt - cartProductAmt?.amt > 0 ? cartProductAmt?.amt+deliveryCharge : cartProductAmt?.amt)
                            : 
                            // handleLogin()
                            chooseLogin()
                        }
                        class="default-btn btn-bg-three"
                      >
                        Confirm Order
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <div
            class="modal fade"
            id="exampleModalss"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Select address
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  {console.log('----------GET ADDRESS', address)}
                  {/* {userData?.id == "" || userData?.id != undefined ? ( */}
                    {/* <> */}
                      {address?.length != 0 ? (
                        <>
                          {address?.map((i, index) => (
                            <div className="mis-use">
                              <div>
                                <label for="radio-3" class="radio-label">
                                  <span class="title-sec">{i?.name}</span>
                                </label>
                              </div>
                              <p>
                                {i?.city}, {i?.address}, {i?.state},{" "}
                                {i?.pincode}
                              </p>
                              {/* <a class="read-btn">Change </a>  */}
                              <a
                                class="btn-close read-btn default-btn btn-bg-three btn-sec"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                  onUpdateAdd(i);
                                }}
                                //  class="read-btn default-btn btn-bg-three btn-sec"
                              >
                                Select
                              </a>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div class="btn-gru">
                          <a
                            href="#"
                            class="read-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Add New Address
                          </a>
                        </div>
                      )}
                    {/* </>
                  ) : (
                    <div onClick={() => handleLogin()} class="btn-gru">
                      <a class="read-btn" data-bs-dismiss="modal">
                        Login Required
                      </a>
                    </div>
                  )} */}

                  {/* <div className="col-md-12 mx-auto">
          <div class="mis-use"><div class="radio">
            <input id="radio-1" name="radio" type="radio" class=""/>
            <label for="radio-1" class="radio-label">
                <span class="title-sec">Dhananjay Chauhan</span></label></div>
                <p>R-1208 gHBJS, DMNFK DF, DHKJFOLFRE, KJFODL</p>
                <a class="read-btn default-btn btn-bg-three btn-sec">Select</a>
          </div></div> */}
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Add New Address
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group has-error">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      class="form-control"
                      required=""
                      data-error="Please enter your name"
                      placeholder="Enter Full Name"
                    />
                  </div>

                  <div class="form-group has-error">
                    <input
                      type="text"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      class="form-control"
                      required=""
                      data-error="Please enter your mobile number"
                      placeholder="Enter Your Mobile Number"
                    />
                  </div>

                  <div class="form-group has-error">
                    <input
                      type="text"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                      required=""
                      data-error="Enter Your House no. / Colony"
                      placeholder="Enter Your House no. / Colony"
                    />
                  </div>
                  <div class="form-group has-error">
                    <input
                      type="text"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                      class="form-control"
                      required=""
                      data-error="Enter Your City"
                      placeholder="Enter Your City"
                    />
                  </div>
                  <div class="form-group has-error">
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      class="form-control"
                      required=""
                      data-error="Enter Your State"
                      placeholder="Enter Your State"
                    />
                  </div>
                  <div class="form-group has-error">
                    <input
                      type="text"
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                      class="form-control"
                      required=""
                      data-error="Enter Your PinCode"
                      placeholder="Enter Your PinCode"
                    />
                  </div>
                  <div className=" btn-save">
                    {pinCode != "" ? (
                      <button
                        onClick={() => {
                          addAdrress();
                        }}
                        data-bs-target="#exampleModal"
                        href="#menu2"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        Save New Address
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          addAdrress();
                        }}
                        type="button"
                        style={{ backgroundColor: "grey" }}
                        class="btn btn-primary"
                      >
                        Save New Address
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
              isOpen={modalOpen}
              onRequestClose={() => setModalOpen(false)}
              style={customStyles}
            >
          
            <div class="modal-dialog" style={{}}>
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabelss1">
                  Choose an account {'\n'} for checkout!
                  </h1>
                  <button
                    onClick={() => {
                      setModalOpen(false)
                    }}
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>


                <div class="modal-body">
                  <div  className="btn-save">
                      <button
                        onClick={() => {
                          handleLogin()
                        }}
                        type="button"

                        style={{backgroundColor: "#84B441", width:220}}
                        class="btn btn-primary"
                      >
                        Login
                      </button>
                  </div>
                  <div style={{marginTop:10,textAlign:'center',  }} className="">
                      <button
                        onClick={() => {
                          guestCheckout()
                        }}
                        type="button"
                        style={{ borderColor:'#9bc265', borderRadius:10, height:45, borderWidth:2, width:220}}
                        // class="btn btn-primary"
                      >
                        Guest Checkout
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Footer />
        </>
      )}
    </>
  );
}

export default Cartdetails;
