import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Blogpage from "../components/Blogpage";

function Blogs(){
    return(
        <>
        <Header/>
        <Blogpage/>
        <Footer/>
        </>
    )
}

export default Blogs;
