import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Product from "../components/Product";
import Videoadd from "../components/Videoadd";
import Testimonial from "../components/Testimonial";
import Newsletter from "../components/Newsletter";
import Readblog from "../components/Readblog";
import Latestproduct from "../components/Latestproduct";
import { ToastContainer } from "react-toastify";

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { deviceDetect } from 'react-device-detect';





function Home(){
    const [getNew , setNew] = useState('')
    // const [deviceId, setDeviceId] = useState(null);

    // Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    //...
  };
  
  const app = initializeApp(firebaseConfig);

  const { deviceType, osName, osVersion, device } = deviceDetect();
  const deviceId = navigator.userAgent;
  const deviceToken = ""; // Not applicable in this context

  
    // useEffect(() => {
    //   const getDeviceId = async () => {
    //     try {
    //       const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    //       const cameras = mediaDevices.filter(device => device.kind === 'videoinput');
    //       console.log('--------mediaDevicess', cameras[0] )
          
    //       if (cameras.length > 0) {
    //         setDeviceId(cameras[0].deviceId);
    //          console.log('---------initial firebase setup', cameras[0].deviceId)
    //       }
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //   };
  
    //   getDeviceId();
    // }, []);






    return(
        <>
        <ToastContainer/>
        <Header/>
        <Banner/>
        <Product/>
        <Videoadd/>
        <Latestproduct/>
        <Testimonial/>
        <Newsletter/>
        <Readblog/>
        <Footer/>
        </>
    )
}

export default Home;
