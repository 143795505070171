import React from "react";
function Contactpage() {
  return (
    <>
      <video
        className="vedio-contact"
        playsinline
        autoPlay
        muted
        loop
        poster="./assets/images/garden.mp4"
      >
        <source src="./assets/images/garden.mp4" type="video/webm" />
      </video>

      <div className="contact-form-area pt-45 pt-bot">
        <div className="container pt-100 ">
          <div className="section-title text-center">
            <h2>Send us a message</h2>
            <p>
              Feel free to fill out the form and reach to us. <br /> We will get
              back to you shortly.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="contact-form form-cont">
                <form id="contactForm">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          required=""
                          data-error="Please enter your name"
                          placeholder="Name"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          required=""
                          data-error="Please enter your email"
                          placeholder="Email"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone_number"
                          id="phone_number"
                          required=""
                          data-error="Please enter your number"
                          className="form-control"
                          placeholder="Phone"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="msg_subject"
                          id="msg_subject"
                          className="form-control"
                          required=""
                          data-error="Please enter your subject"
                          placeholder="Your Subject"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="8"
                          required=""
                          data-error="Write your message"
                          placeholder="Your Message"
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="btn-sub">
                      <button
                        type="submit"
                        className="default-btn btn-bg-three"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactpage;
