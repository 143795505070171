import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogComponent from "../components/BlogComponent";

function Blog(){
    return(
        <>
        <Header/>
        <BlogComponent/>
        <Footer/>
        </>
    )
}

export default Blog;
