import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Productpageproduct from "../components/Productpageproduct";
window.scrollTo(0, 0)

function Productpage(){
    const [message, setMessage] = useState('')


    const chooseMessage = (message) => {
        setMessage(message);
      };


    return(
        <>
        <Header data={message}/>
        <Productpageproduct chooseMessage={chooseMessage}/>
        <Footer/>
        </>
    )
}

export default Productpage;
