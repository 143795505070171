import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contactpage from "../components/Contactpage";

function ContactUs(){
    return(
        <>
        <Header/>
        <Contactpage/>
        <Footer/>
        </>
    )
}

export default ContactUs;
