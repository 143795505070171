import React, { useEffect, useMemo, useState } from "react";
import ApiModel from "../Utils/ApiModel";
import Loader from "../components/loader";
window.scrollTo(0, 0);

function Product({ isSuccess }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      get_cat();
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const get_cat = async () => {
    setIsLoading(true);
    ApiModel.sendApiCall(
      `category`,
      null,
      "GET",
      (response) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (response?.data?.status === 200) {
          setData(response?.data?.data);
        } else {
          setIsLoading(false);
          setData([]);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error is", error);
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section className="product pro-about">
            <div className="offer-area pb-70">
              <div className="container">
                <div className="section-title text-center">
                  <img
                    src="assets/images/Group-114.png"
                    className="vert-move"
                  ></img>
                  {/* <i className="flaticon-leaf"></i> */}
                  <h2 className="animate__shakeY">What are you looking for?</h2>
                  <p>
                    Ped Paudhe is an official website which provided best plants
                    to our customer. Ped paudhe have all type of variety in
                    plants & flowers.
                  </p>
                </div>
                <div className="row pt-45 justify-content-center">
                  {data
                    ?.filter((i) => {
                      const remove = "All";
                      return i?.name !== remove;
                    })
                    .map((object, index) => (
                      <div className="col-lg-3 col-sm-6 col-6">
                        <div className="offer-item ">
                          <div class="feature-two__shape-1">
                            <img
                              decoding="async"
                              src="https://bracketweb.com/ogenixwp/wp-content/uploads/2023/02/feature-two-shape-1.png"
                              alt="feature-two-shape-1"
                              title="feature-two-shape-1"
                            />
                          </div>
                          <div className="offer-img">
                            <a href="product">
                              <img
                                loading="lazy"
                                src={object.thumbnail}
                                alt="Product Images"
                              />
                            </a>
                          </div>
                          <div className="content">
                            <h3>
                              <a href="product">
                                {object.name.length > 15
                                  ? object.name.substring(0, 15) + "..."
                                  : object.name}
                              </a>
                            </h3>
                            <p>
                              The Kalanchoe Red is a perennial succulent with
                              thick leaves and eye-catching crimson-red flowers
                              that grow in clusters through the year.
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default Product;
