import React, { useEffect, useMemo, useState } from "react";
import ApiModel from "../Utils/ApiModel";
import { Colors } from "../Utils/AppConstant";
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify";
import { toast_func } from "../Utils/Toastify/toaster";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../components/loader"

    function Productpageproduct({ chooseMessage }){
    const [productsBkp, setProductsBkp] = useState([])
    const [category, setCategory] = useState([])
    const [categoryBkp, setCategoryBkp] = useState([])
    const [cartData, setCartData] = useState([])
    const [search, setSearch] = useState('')
    const [categoryId, setCategoryId] = useState(4)
    const value = window.localStorage.getItem('userdata');
    const [activeCat, setActiveCat] = useState('All')
    const userData = JSON.parse(value);
    const [isLoading, setIsLoading] = useState(false);
    const [ products, setProducts] = useState([])
 


    useEffect(()=>{
        let isCancelled = false
        if (!isCancelled) { 
            get_category() 
            get_product()
            // get_cart()
        } 
        return () => { 
            isCancelled = true; 
        };
    },[])

    const get_category = async() => {
        // setIsLoading(true)
        ApiModel.sendApiCall(
            `category`,
            null,
            'GET',
            (result) => {
                setTimeout(() => {
                    // setIsLoading(false)
                   }, 1500);
                if(result?.data?.status === 200){
                    setCategory(result?.data?.data)
                    setCategoryBkp(result?.data?.data)
                }else{
                    // setIsLoading(false)
                }
            },
            (error) => {
                    setIsLoading(false)
                console.log('the error isi ', error)
            }
        )
    }
    const get_product = async() => {
        setIsLoading(true)
        ApiModel.sendApiCall(
            `product`,
            null,
            'GET',
            (result) => {
                setTimeout(() => {
                    setIsLoading(false)
                   }, 1500);
                if(result?.data?.status === 200){
                    setProducts(result?.data?.data)
                    setProductsBkp(result?.data?.data)
                }else{
                    setIsLoading(false)
                    console.log('the result is empty')
                }
            },
            (error) => {
                setIsLoading(false)
                console.log('the error in product page is',error)
            }
        )
    }

    // const get_cart = async() => {
    //     const res = await axios.get('https://geolocation-db.com/json/')
    //     let finalId ={
    //     userId:  userData?.id == '' || userData?.id != undefined ? userData?.id : res.data.IPv4
    //     }
    //     ApiModel.sendApiCall(
    //         `getaddtocart/${finalId?.userId}`, //change after user id get
    //         null,
    //         'GET',
    //         (result) => {
    //             if(result?.data?.status === 200){
    //                 setCartData(result?.data?.items)
    //             }else{
    //                 console.log('the else condition work in header section')
    //             }
    //         },
    //         (error) => {
    //             console.log('the error is===>', error)
    //         }
    //     )
    // }

 

    const search_product = async(txt) => {
        let data = await products?.filter(i => (i?.name?.toLowerCase()).includes(txt.toLowerCase()))
        if(txt?.length > 0){
            setProducts(data)
        }else{
            setProducts(productsBkp)
        }
    }

    const filter_product = async(item) => {
        setCategoryId(item?.id ? item?.id : item);
        let name = item?.name ? item?.name : 'All';
        setActiveCat(name)
        if (name === 'All') {
            setProducts(productsBkp);
        } else {
            let a = productsBkp?.filter((item, index) => {
                return item?.catname === name;
            });
        setProducts(a);
        }
    }
    


    const addToCart = async(item) => {
        const res = await axios.get('https://geolocation-db.com/json/')
        let finalId ={
        userId:  userData?.id == '' || userData?.id != undefined ? userData?.id : res.data.IPv4
        }
        let data = JSON.stringify({
            id:(item?.id).toString(),
            img:item?.image,
            name:item?.name,
            stock:0,
            offerPrice:item?.offerprice,
            actualPrice:item?.price,
            qty:1,
            userid:finalId?.userId //change after user registerd
        })
        ApiModel.sendApiCall(
            `addtocart`,
            data,
            null,
            (result) => {
                if(result?.data?.status === 200){
                   toast_func("success",result?.data?.success)
                   chooseMessage(result?.data)
                //    get_cart()
                //    get_product()
                }else{
                    toast_func("warning", result?.data?.sussess )
                }
            },
            (error) => {
                console.log('the eror----+++++',error)
            }
        )
    }

    const goToCart = async() => {
        window.location.href = "/Cart"
    }
    
    return(
        <>
        {isLoading ? <Loader/> : 
        <>
        <section className="product page-product">
        <div className="offer-area pt-100 pb-70">
        <ToastContainer />
        <div className="container text-center">
            <div className="section-title text-center">
                <h2 >Products</h2>
                <div class="rov" style={{display:'flex', alignItems:'center',  marginTop:30}}>
                    {category?.map(i => {
                        return(
                            <div  onClick={()=>filter_product(i)} style={{marginTop:'auto', position:'relative', alignSelf:'center', alignItems:'center', marginLeft:10, marginRight:10}}>
                                <h4 style={{fontSize:'medium', color: i?.name === activeCat ? '#84b441' : Colors.textColor, cursor:'pointer'}}>{i?.name}</h4>
                                <div className="line" style={{background: i?.name === activeCat ? '#84b440' : null, width: 12, marginTop:-5, borderRadius:20, height: 3, position: 'absolute', left: '50%',}}></div>
                            </div>
                        )
                    })}
                    <div className="center">
                    <input className="input-file" placeholder="Search Products..." value={search} onChange={(txt)=>{
                        setSearch(txt.target.value)
                        search_product(txt.target.value)
                    }} type='search' style={{backgroundColor:'transparent', borderRadius:10, borderColor:'#84B441', borderWidth:2,opacity:1, textAlign:'center', right:'6%', position:'absolute'}} />

                    </div>
                    <div className="subscribe">
                    <img src="assets/images/Searc-filed.gif"></img>
                    </div>
                </div>
            </div>
            <div className="row pt-45 justify-content-center">


            {products.map((item, index) => {
                let a = cartData?.some(i => i?.productid === item?.id)
                return(
                    <div className="col-lg-3 col-sm-6 col-6">
                        <div className="product-item">
                            <div className="product-img">
                                {/* change cursor here  */}
                                <Link to={{
                                    pathname:"/productdetails",
                                    state:{data:item}
                                }}>
                               
                                    <img className="img1" style={{cursor:"pointer"}} src={item?.image} alt="Product Images"/>
                                    </Link> 
                            </div>
                          
                            <div className="content">
                                <a style={{fontSize:'medium', color:Colors.textColor}}>{item?.name.length > 20 ? item?.name.substring(0, 20) + "..." : item?.name}</a>
                                <p style={{fontSize:'small', color:Colors.textColor}} dangerouslySetInnerHTML={{ __html: item?.keyfeatures.length > 40 ? item?.keyfeatures.substring(0, 40) + "..." : item?.keyfeatures}} />
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <h2 style={{fontSize:'medium',fontWeight:'bold', color:Colors.textColor}}>{"\u20B9" + item?.offerprice}</h2>
                                    <h3 style={{fontSize:'medium',fontWeight:'lighter',color:Colors.gray, textDecoration:'line-through', marginLeft:5,marginBottom:'auto'}}>{"\u20B9" + item?.price}</h3>
                                </div>
                                <button onClick={()=> { a? goToCart() : addToCart(item)}} className="order-btn">{a ? 'View Cart' : 'Add to Cart'}</button>
                            </div>
                            
                        </div>
                    </div>
                )
            })}
            </div>
            {/* <button className="order-btn">View All</button> */}
        </div>
        </div>
     
        </section>
        </>}</>
        
    )
}

export default Productpageproduct;
