import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Aboutpage from "../components/AboutUspage";
import Product from "../components/Product";
window.scrollTo(0, 0)

function AboutUs(){
    return(
        <>
        {/* Api implementation left */}
        <Header/>
        <Aboutpage/>
        <Product isSuccess ={true}/>
        <Footer/>
        </>
    )
}

export default AboutUs;
