import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Colors } from "../Utils/AppConstant";
import ApiModel from "../Utils/ApiModel";
import Loader from "../components/loader";

function Blogpage() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation().state.data;

  const get_blogs = async () => {
    setIsLoading(true);
    ApiModel.sendApiCall(
      `blog`,
      null,
      "GET",
      (response) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (response?.data?.status === 200) {
          setData(response?.data?.data);
        } else {
          setIsLoading(false);
          setData([]);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error in blog page is===>", error);
      }
    );
  };
  useEffect(() => {
    get_blogs();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section className="blog-area pt-100 pb-70">
            <div className="container text-center">
              <div className="row pt-45 justify-content-center">
                <div>
                  <div className="blog-card blog-color-2">
                    <a href="#">
                      <img
                        src={location?.image}
                        alt={"Blog"}
                        style={{ width: "100%", height: 331 }}
                      />
                    </a>
                    <div className="content">
                      <h3
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          color: Colors.textColor,
                        }}
                      >
                        {location.title}
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: location?.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-title text-center">
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 28,
                    color: Colors.textColor,
                  }}
                >
                  Other Blogs
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: 16,
                    color: Colors.textColor,
                  }}
                >
                  The blogs are available to learn how plant is more important
                  in our life. How we can manage the plants to grow bigger.
                </p>
              </div>

              <div class="row pt-45 justify-content-center">
                {data?.map((object, index) => (
                  <div class="col-lg-3 col-md-4 col-6">
                    <div class="blog-card blog-color-2">
                      <a href="#">
                        <img loading="lazy" src={object.image} alt="Images" />
                      </a>
                      <div class="content">
                        <p
                          style={{
                            color: Colors.textColor,
                            fontWeight: "bold",
                          }}
                        >
                          {/* {object.title} */}
                          {object.title.length > 50
                            ? object.title.substring(0, 50) + "..."
                            : object.title}
                        </p>
                        <Link
                          className="link-h"
                          style={{ fontWeight: "bold", fontSize: 14 }}
                          to={{
                            pathname: "/blogs",
                            state: { data: object },
                          }}
                        >
                          Read More
                        </Link>
                        {/* <a href="/blogs" class="read-btn">Read More</a> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className="order-btn">Load More</button>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default Blogpage;
