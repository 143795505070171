import React from "react";
function Footer() {
  return (
    <>
      <footer className="footer-area footer-bg">
        <div className="container">
          <div className="footer-top pt-100">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget footer-widget-color-2">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img
                        loading="lazy"
                        src="assets/images/Logo-of-pedh-paudha.png"
                        className="footer-logo1"
                        alt="Images"
                      />
                      {/* <img loading="lazy" src="assets/images/Logo-of-pedh-paudha.png" className="footer-logo2" alt="Images"/> */}
                    </a>
                  </div>
                  {/* <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            </p> */}
                  <div
                    style={{}}
                    className="footer-widget footer-widget-color-2"
                  >
                    <ul className="social-link">
                      <li>
                        <a
                          href="https://www.facebook.com/pedhpaudha/"
                          target="_blank"
                        >
                          <i className="bx bxl-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/pedhpaudha/"
                          target="_blank"
                        >
                          <i className="bx bxl-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.pinterest.com/pedhpaudha/"
                          target="_blank"
                        >
                          <i className="bx bxl-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget footer-widget-color-2">
                  <h3>Information</h3>
                  <ul className="footer-list">
                    <li>
                      <a href="#" target="_blank">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        Products
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="/privacy-policy" target="_blank">
                        Privacy & Policy
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        Terms of Service
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget-color-2">
                            <h3></h3>
                            <ul className="footer-list">

                            </ul>
                        </div>
                    </div> */}
              <div className="col-lg-5 col-md-6 offset-lg-1">
                <h3 className="footer-help">Help</h3>
                <div class="contact-info-item">
                  <div class="icon">
                    <span class="icon-background"></span>
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="content">
                    <p>
                      <i class="bx bx-envelope"></i>support@pedhpaudha.com
                    </p>
                  </div>
                </div>
                <div class="contact-info-item">
                  <div class="icon">
                    <span class="icon-background"></span>
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="content">
                    <p>
                      <i class="bx bx-phone"></i>0120 408 0513
                    </p>
                  </div>
                </div>
                <div class="contact-info-item">
                  <div class="icon">
                    <span class="icon-background"></span>
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="content">
                    <p>
                      <i class="bx bx-map"></i>Procapitus Business Park D 247,
                      Office No. 302, Sector 63, Noida, UP 201301
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="footer-bottom">
              <div class="row align-items-center">
                <div class="col-lg-12 m-auto">
                  <div class="copyright-text">
                    <p>
                      © Copyright 2023{" "}
                      <a href="https://pedhpaudha.com/">Pedh Paudha</a>. All
                      Rights Reserved{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
