// import React from "react";
import React, { useEffect, useMemo, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ApiModel from "../Utils/ApiModel";
import Loader from "../components/loader";
// import { v4 as uuidv4 } from 'uuid';

function Banner() {
  const [banner, setData] = useState([]);
  const value = window.localStorage.getItem("userdata");
  const userData = JSON.parse(value);
  const [isLoading, setIsLoading] = useState(false);
  // const generatedUUID = uuidv4();
  const [itemId, setItemId] = useState('');

  const generateUniqueId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 8;
    let id = '';
  
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters[randomIndex];
    }
    return id;
  };
  

  useEffect(() => {
    const fetchUniqueId = async () => {
      const uniqueId = await generateUniqueId(); // यूनिक आइडी उत्पन्न करने के लिए एक एसिंक्रोनस् फ़ंक्शन का उपयोग करें
      if (!itemId) {
        setItemId(uniqueId);
      }
    };

    fetchUniqueId();
  }, [itemId]);

  // console.log('----------ONLY ONE UNIQUE', itemId)


  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      get_banners();
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const get_banners = async () => {
    setIsLoading(true);
    ApiModel.sendApiCall(
      `banners`,
      null,
      "GET",
      (response) => {
        setIsLoading(false);
        if (response?.data?.status === 200) {
          setData(response?.data?.data);
        } else {
          setIsLoading(false);
          setData([]);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error in blog page is===>", error);
      }
    );
  };

  return (
    <>
      {/* {isLoading ? <Loader/> : 
    <> */}
      <div className="banner-area-two">
        <div className="container">
          <div className="row ">
            <div className="col-lg-5">
              <div className="banner-content-2 banner-title">
                <span
                  className="one-two"
                  style={{
                    marginBottom: "0",
                    fontWeight: 600,
                    fontSize: "1rem",
                    color: "red",
                  }}
                >
                  Healthy life with{" "}
                </span>
                <h2>Nature & Plants</h2>
                <p>
                  Nature is the ultimate source of our living. Both living and
                  non-living things include nature, and everyone is
                  interdependent, which helps maintain the ecosystem. Plants,
                  animals, and humans all depend on nature for their survival.
                </p>
                <a
                  href="/product"
                  className="default-btn btn-bg-three border-radius-5"
                >
                  Explore Now
                </a>
              </div>
            </div>

            <div className="col-lg-7">
              {/* <Carousel style={{ display: 'flex' }} autoPlay={true} showArrows={false} infiniteLoop={true}>
            {banner?.map((i, index) => (
                  <div className="banner-img-2">
                    <img loading="lazy" src={i?.thumbnail} alt="Banner Images" />
                  </div>
                   ))}
                   </Carousel> */}

              <div
                id="carouselExampleFade"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
              >
                {/* <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div> */}
                <div class="carousel-inner">
                  <div class="carousel-item active" data-bs-interval="3000">
                    <img
                      src="https://gazingtechnosoft.net/swati/gazingnursery/public/admin/images/thumbnail1681379919.png"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item" data-bs-interval="3000">
                    <img
                      src="https://gazingtechnosoft.net/swati/gazingnursery/public/admin/images/thumbnail1681380532.png"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item" data-bs-interval="3000">
                    <img
                      src="	https://gazingtechnosoft.net/swati/gazingnursery/public/admin/images/thumbnail1681379942.png"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-container">
        <video
          src="video/plants.mp4"
          type="video/mp4"
          poster="Final Render_PosterImage.png"
          width="100%"
          muted
          loop
          autoPlay
        ></video>
      </div>
      {/* </>
    } */}
    </>
  );
}

export default Banner;
