import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { toast_func } from "../Utils/Toastify/toaster";
import ApiModel from "../Utils/ApiModel";
import { ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Colors } from "../Utils/AppConstant";

function Login() {
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [resetOtp, setResetOtp] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [passwordTrue, setPasswordTrue] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [forgetData, setForgetData] = useState("");
  const history = useHistory();
  const navigateTo = () => history.push("/");
  window.scrollTo(0, 0);

  const handleLogin = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    if (emailId != "") {
      if (password != "") {
        let body = {
          email: emailId,
          password: password,
          deviceId: res.data.IPv4,
          // type:checkType
        };
        ApiModel.sendApiCall(
          `login`,
          body,
          null,
          (result) => {
            if (result?.data?.status === 200) {
              localStorage.setItem(
                "userdata",
                JSON.stringify(result?.data?.sussess?.id)
              );
              navigateTo();
              toast_func("success", "Login Successfully!");
            } else {
              console.log("----------hit elseeee");
              toast_func("warning", "Please enter valid credentials");
            }
          },
          (error) => {
            console.log("the error is ===>", error);
          }
        );
      } else {
        toast_func("warning", "Please enter your password");
      }
    } else {
      toast_func("warning", "Please enter your email");
    }
  };

  const forgetPassword = async () => {
    if (resetEmail !== "") {
      let body = {
        email: resetEmail,
      };
      ApiModel.sendApiCall(
        `findemail`,
        body,
        null,
        (result) => {
          if (result?.data?.status === 200) {
            setForgetData(result?.data);
            toast_func(
              "success",
              "OTP has been sent to your email. Please check your email id"
            );
            setPasswordTrue(true);
          } else {
            toast_func("warning", "Email id is not registered with us");
          }
        },
        (error) => {
          console.log("the error is ===>", error);
        }
      );
    } else {
      toast_func("warning", "Please enter your email");
    }
  };

  const handleUpdatePassword = async () => {
    let body = {
      id: forgetData?.id,
      password: resetPassword,
    };
    if (resetOtp == forgetData?.otp) {
      ApiModel.sendApiCall(
        `updatepassword`,
        body,
        null,
        (result) => {
          if (result?.data?.status === 200) {
            setForgetData(result?.data);
            toast_func("success", "Password Updated successfully!");
            setPasswordTrue(false);
            setModelClose(false);
            setResetPassword("");
            setResetOtp("");
            setResetEmail("");
          } else {
            toast_func(
              "warning",
              "Password should not be same as per old password"
            );
          }
        },
        (error) => {
          console.log("the error is ===>", error);
        }
      );
    } else {
      toast_func("warning", "Please enter valid otp");
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <section className="login">
        <div class="container">
          <div class="row">
            <div className="video-sec">
              <video
                src="video/logs.mp4"
                type="video/mp4"
                poster="Final Render_PosterImage.png"
                width="100%"
                muted
                loop
                autoPlay
              ></video>
            </div>

            <div class="user-area1 login-patrs">
              <div class="user-width">
                <div class="user-form">
                  <div class="contact-form">
                    {/* <div class="logo-center">
<a class="navbar-brand title">

    <img src="assets/images/logos/logo.png" class="logo-login" loading="lazy" alt="Logo"/>
</a>
</div> */}
                    {/* <form> */}
                    <div class="row">
                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <label>Email Address *</label>
                          <input
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                            type="text"
                            class="form-control"
                            required=""
                            data-error="Please enter your Username or Email"
                            placeholder=""
                          />
                          <i class="bx bx-envelope"></i>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Password *</label>
                          <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            class="form-control"
                            type="password"
                            name="password"
                            placeholder=""
                          />
                          <i class="bx bx-low-vision"></i>
                        </div>
                      </div>
                      <div class="col-lg-12 form-condition">
                        <div class="agree-label">
                          <label
                            onClick={() => setModelClose(true)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "#84B441",
                              fontSize: 15,
                            }}
                            for="chb11"
                            className="labelss"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModaldf"
                          >
                            Forget Password
                          </label>
                          <a
                            style={{
                              marginTop: 2,
                              fontSize: 14,
                              color: Colors.textColor,
                            }}
                            class="forget"
                            href="forget-password.html"
                          >
                            don't have an account?{" "}
                            <a
                              href="/Register"
                              style={{
                                textDecoration: "underline",
                                fontSize: 15,
                              }}
                              className="register-now"
                            >
                              Register here
                            </a>
                          </a>
                        </div>
                        {/* <div class="agree-label">
<input type="checkbox" id="chb1"/>
<label for="chb1">
I agree Tserm of the Services
</label>
</div> */}
                        <div class="new">
                          {/* <form> */}
                          <div class="form-group">
                            <input type="checkbox" id="html" />
                            <label for="html">
                              I agree Tserm of the Services
                            </label>
                          </div>

                          {/* </form> */}
                        </div>
                      </div>
                      <div href class="col-lg-12 ">
                        <button
                          onClick={() => handleLogin()}
                          type="submit"
                          class="default-btn btn-bg-three btn-sign"
                        >
                          Sign In
                        </button>
                        <button
                          type="submit"
                          class="default-btn btn-bg-three btn-sign google-lpus"
                        >
                          Continue in with Google{" "}
                          <img
                            src="assets/images/google.png"
                            className="google-plus"
                          ></img>
                        </button>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="modal fade"
        id="exampleModaldf"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="contact-form _boxzooms">
                <div class="row">
                  <div class="col-lg-6 ">
                    <div className="form-images">
                      <img src="assets/images/Group-25179.png"></img>
                    </div>
                  </div>
                  <div class="col-lg-6 ">
                    <h1>Forget Password</h1>
                    <div class="form-group">
                      <input
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                        class="form-control"
                        type="text"
                        placeholder="Please enter your email"
                      />
                    </div>

                    {passwordTrue && (
                      <>
                        <div class="form-group">
                          <input
                            value={resetOtp}
                            onChange={(e) => setResetOtp(e.target.value)}
                            class="form-control"
                            type="text"
                            placeholder="Enter OTP"
                          />
                        </div>

                        <div class="form-group">
                          <input
                            value={resetPassword}
                            onChange={(e) => setResetPassword(e.target.value)}
                            class="form-control"
                            type="password"
                            placeholder="Enter Password"
                          />
                        </div>

                        <button
                          onClick={() => handleUpdatePassword()}
                          data-bs-dismiss="modal"
                          type="submit"
                          class="default-btn btn-bg-three btn-sign"
                        >
                          Update{" "}
                        </button>
                      </>
                    )}

                    {!passwordTrue && (
                      <button
                        onClick={() => forgetPassword()}
                        type="submit"
                        class="default-btn btn-bg-three btn-sign"
                      >
                        Update{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Login;
