import React from "react";
import {BrowserRouter as Router , Route , Switch } from "react-router-dom";
import Home from "./pages/Home";
import Productpage from "./pages/Productpage";
import Blogs from "./pages/Blogs";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Productdetails from "./components/Productdetails";
import Cart from "./components/Cart";
import Cartdetails from "./components/Cartdetails";
import Profile from "./components/Profile";
import Login from "./components/Login";
import Blog from "./pages/Blog";
import PrivacyPolicy from "./components/privacy-policy";
import OrderSuccessfully from "./components/OrderSuccessfully";
import Register from "./components/Register";
const App = () =>{
  window.scrollTo(0, 0)

  return(
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/product" component={Productpage} />
        <Route exact path="/Blog" component={Blog} />
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/About-Us" component={AboutUs} />
        <Route exact path="/Contact-Us" component={ContactUs} />
        {/* <Route   path="/productdetails" render={(props)=> <Productdetails {...props} />} /> */}
        <Route   path="/productdetails" component={Productdetails} />
        {/* <Route   path="/productdetails/:id" component={Productdetails} /> */}
        <Route   path="/cart" component={Cart} />
        <Route   path="/Cartdetails" component={Cartdetails} />
        <Route   path="/Profile" component={Profile} />
        <Route   path="/Login" component={Login} />
        <Route path="/privacy-policy" component={PrivacyPolicy}/>
               <Route path="/OrderSuccessfully" component={OrderSuccessfully}/>
               <Route path="/Register" component={Register}/>
      </Switch>
    </Router>
  );
}


export default App;