import React from "react";
function Videoadd() {
  return (
    <>
      <div className="choose-area-three">
        <div className="container">
          <div className="row pt-45 ">
            <div className="col-lg-8">
              <div className="choose-img">
                <video
                  src="assets/images/finalrender.mp4"
                  type="video/mp4"
                  poster="Final Render_PosterImage.png"
                  width="100%"
                  muted
                  loop
                  autoPlay
                ></video>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="section-title">
                <h2>
                  Download our app<br></br>
                </h2>
                <p>
                  The easiest way to access our product and order directly from
                  our app which is available for Android & IOS.
                </p>
              </div>

              <div class="row" style={{ marginBottom: 15 }}>
                <div className="col-lg-4">
                  <div className="dowload-apps">
                    <img
                      loading="lazy"
                      src="assets/images/QRCode.png"
                      style={{ height: 140, marginTop: 5, width: 160 }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="fileImg">
                    <img
                      loading="lazy"
                      src="assets/images/cloudfare.542967eb.png"
                      style={{
                        height: 50,
                        marginTop: 5,
                        marginLeft: 10,
                        width: 100,
                      }}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      src="assets/images/comodo.b12d90f7.png"
                      style={{
                        height: 50,
                        marginTop: 15,
                        marginLeft: 10,
                        width: 100,
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="dowload-apps">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.pedhpaudha"
                >
                  <img
                    loading="lazy"
                    src="assets/images/play.png"
                    className="download"
                    alt=""
                  />
                </a>

                <a
                  target="_blank"
                  href="https://apps.apple.com/in/app/pedh-paudha/id6447099704"
                >
                  <img
                    loading="lazy"
                    src="assets/images/app.png"
                    className="download"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Videoadd;
