export const Colors = {
    themeColor: '#B9F6CA',
    white: '#ffffff',
    offWhite: "#F3F5F9",
    offWhite2: "#F6F5FB",
    lightWhite: '#F5F5F5',
    black: '#000000',
    gray: '#D1D0CE',
    lightBlue: '#87CEFA',
    blue: '#0000FF',
    neonBlue: '#b6b6ff',
    green: '#00FF00',
    buttonGreen: '#388E3C',
    lightGreen: '#43A047',
    dimGreen: '#A5D6A7',
    red: '#FF0000',
    orange: '#FFA500',
    pink: '#FFC0CB',
    yellow: '#FFFF00',
    dimYellow: '#E6EE9C',
    mintCream: '#F5FFFA',
    borderColor:'#A5D6A766',
    buttonColor:'#A5D6A7',
    textColor: '#0F2D52', //new theme color for text,
    backgroundColor:"#84B441" //background color
  };



  // const Images = {
  //   loader: require('../../public/assets/images/loader.gif'),
  // }
  // export default Images;