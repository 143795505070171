import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ApiModel from "../Utils/ApiModel";
import { Colors } from "../Utils/AppConstant";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Loader from "../components/loader";

function Cart() {
  const [cartData, setCartData] = useState([]);
  const [actualBill, setActualBill] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [total, setTotal] = useState(totalBill);
  const [reData, setReData] = useState("");
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(49);
  const value = window.localStorage.getItem("userdata");
  const userData = JSON.parse(value);
  const history = useHistory();
  const navigateTo = () => history.push("/Cartdetails");
  const [isLoading, setIsLoading] = useState(false);
  const [leftAmt, setLeftAmt] = useState(499)

  const get_cart = async () => {
    // setIsLoading(true)
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    ApiModel.sendApiCall(
      `getaddtocart/${finalId?.userId}`, //change after user id get
      null,
      "GET",
      (result) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (result?.data?.status === 200) {
          setCartData(result?.data?.items);
        } else {
          setIsLoading(false);
          console.log("the else condition work in cart section");
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error is===>", error);
      }
    );
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      get_cart();
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const calculateBill = () => {
    const initialValue = 0;
    const total = cartData?.reduce(
      (accumulator, current) =>
        accumulator + current?.offerPrice * current?.qty,
      initialValue
    )

    const total_2 = cartData?.reduce(
      (accum, curr) => accum + curr?.actualPrice * curr?.qty,
      initialValue
    );
    let remaining = total_2 - total;
    setActualBill(remaining);
    setTotalBill(total);
  };

  useMemo(() => {
    calculateBill();
  }, [cartData]);

  const updateCart = async (type, item) => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id.toString()
          : res.data.IPv4,
    };
    if (type == "Increase") {
      let increaseBody = {
        productid: item?.productid,
        qty: parseInt(item?.qty) + 1,
        userid: finalId?.userId, //userData?.id,
      };
      ApiModel.sendApiCall(
        `updatecart`,
        increaseBody,
        null,
        (result) => {
          if (result?.data?.status === 200) {
            get_cart();
            setReData(result?.data);
          } else {
            console.log("te error is==>");
          }
        },
        (error) => {
          console.log("the error is ===>", error);
        }
      );
    } else {
      let decreaseBody = {
        productid: item?.productid,
        qty: parseInt(item?.qty) - 1,
        userid: finalId?.userId, //userData?.id,
      };
      ApiModel.sendApiCall(
        `updatecart`,
        decreaseBody,
        null,
        (result) => {
          if (result?.data?.status === 200) {
            get_cart();
            setReData(result?.data);
          } else {
            console.log("te error is==>");
          }
        },
        (error) => {
          console.log("the error is ===>", error);
        }
      );
    }
  };


  // const onCheckout = () => {
  //   navigateTo({
  //     cartData: cartData,
  //     amount: {
  //       amt: totalBill,
  //       taxes: tax,
  //       delivery: deliveryCharge,
  //       discounts: discount,
  //       total: total,
  //     }
  //   })
  // };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header data={reData} />

          <section class="cart-wraps-area ptb-100 cartsss padd-sec ">
            <div class="container">
              {cartData?.length != 0 ? (
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <form>
                      <div class="cart-wraps wraps-cart cart-paddd">
                        <div class="cart-table table-responsive">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Quantity</th>
                                <th scope="col" class="total">
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartData?.map((item) => {
                                return (
                                  <tr>
                                    <td class="product-thumbnail thumb">
                                      <a href="#">
                                        <img src={item?.img} alt={item?.name} />

                                        <div className="flex-sec">
                                          <h1
                                            style={{
                                              fontSize: "medium",
                                              color: Colors.textColor,
                                            }}
                                          >
                                            {item?.name}
                                          </h1>
                                          <h2
                                            style={{
                                              fontSize: "medium",
                                              fontWeight: "bold",
                                              color: Colors.textColor,
                                            }}
                                          >
                                            {"\u20B9" + item?.offerPrice}
                                          </h2>
                                          <h3
                                            style={{
                                              fontSize: "medium",
                                              fontWeight: "lighter",
                                              color: Colors.gray,
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {"\u20B9" + item?.actualPrice}
                                          </h3>
                                        </div>
                                      </a>
                                    </td>

                                    <td class="product-quantity thumb">
                                      <div class="input-counter">
                                        <span
                                          class="minus-btn"
                                          onClick={() =>
                                            updateCart("Decrease", item)
                                          }
                                        >
                                          <i class="bx bx-minus"></i>
                                        </span>
                                        <h6
                                          style={{
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                          }}
                                        >
                                          {item?.qty}
                                        </h6>
                                        <span
                                          class="plus-btn"
                                          onClick={() =>
                                            updateCart("Increase", item)
                                          }
                                        >
                                          <i class="bx bx-plus"></i>
                                        </span>
                                      </div>
                                    </td>
                                    <td class="product-subtotal thumb">
                                      <span class="subtotal-amount">
                                        {"\u20B9" +
                                          item?.offerPrice * item?.qty}
                                      </span>
                                      {/* <a href="#" class="remove">
                                  <i class="bx bx-trash"></i>
                                </a> */}
                                    </td>
                                  </tr>
                                );
                              })}
                              {/* <tr>
                          <td class="product-thumbnail thumb">
                            <a href="#">
                              <img
                                src="https://gazingtechnosoft.net/swati/gazingnursery/public/admin/images/thumbnail1668064306.png"
                                alt="Image"
                              />

                              <div className="flex-sec">
                                <h1>Plant One</h1>
                                <p>₹ 860.00</p>
                              </div>
                            </a>
                          </td>

                          <td class="product-quantity thumb">
                            <div class="input-counter">
                              <span class="minus-btn">
                                <i class="bx bx-minus"></i>
                              </span>
                              <input type="text" value="1" />
                              <span class="plus-btn">
                                <i class="bx bx-plus"></i>
                              </span>
                            </div>
                          </td>
                          <td class="product-subtotal thumb">
                            <span class="subtotal-amount">$50.00</span>
                            <a href="#" class="remove">
                              <i class="bx bx-trash"></i>
                            </a>
                          </td>
                        </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6"></div>
                        <div class="col-lg-6 right-side">
                          <div class="cart-totals">
                            <h3>Totals</h3>
                            <h1
                              style={{
                                fontWeight: "normal",
                                color: Colors.textColor,
                                fontSize: "xx-large",
                              }}
                            >
                              {"\u20B9" + totalBill}
                            </h1>
                        {leftAmt - totalBill > 0 ? (
                            <p
                              style={{
                                color: Colors.textColor,
                                fontSize: 12,
                              }}>
                              {`*Add ${'\u20B9'}${leftAmt - totalBill} to get free delivery`}
                            </p>
                          ):(
                              <p
                              style={{
                                fontWeight: "normal",
                                color: Colors.textColor,
                                fontSize: "small",
                              }}
                            >
                              You save {"\u20B9" + actualBill} in this order
                            </p>
                            )}
                            <Link
                              to={{
                                pathname: "/Cartdetails",
                                state: {
                                  data: cartData,
                                  amount: {
                                    amt: totalBill,
                                    taxes: tax,
                                    delivery: deliveryCharge,
                                    discounts: discount,
                                    total: total,
                                  },
                                },
                              }}
                            >
                              <a
                                // onClick={() => onCheckout()}
                                class="default-btn btn-bg-three"
                              >
                                Proceed to checkout
                              </a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col-lg-5 col-md-5 mx-auto">
                    <div class="box-sss">
                      <img
                        src="assets/images/cartEmpty.gif"
                        style={{ height: 270, width: 330 }}
                        class="img-fluid blur-up lazyloaded"
                        alt=""
                      />
                      <p class="para">
                        You haven't added any product in the cart
                      </p>
                      <div class="btn-gru">
                        <a href="/product" class="read-btn">
                          Start Shopping
                        </a>
                      </div>
                      {/* <p class="para" style={{fontWeight:'600'}}>You haven't added any product in the cart</p> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          <Footer />
        </>
      )}
    </>
  );
}

export default Cart;
