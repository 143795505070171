import React, { useState } from "react";
import ApiModel from "../Utils/ApiModel";
import { toast_func } from "../Utils/Toastify/toaster";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Loader from "../components/loader";

function Newsletter() {
  const [name, setName] = useState([]);
  const value = window.localStorage.getItem("userdata");
  const userData = JSON.parse(value);
  const [isLoading, setIsLoading] = useState(false);

  const newsSubscribe = async () => {
    setIsLoading(true);
    const res = await axios.get("https://geolocation-db.com/json/");
    let finalId = {
      userId:
        userData?.id == "" || userData?.id != undefined
          ? userData?.id
          : res.data.IPv4,
    };
    let body = {
      userid: finalId?.userId,
      email: name,
    };
    ApiModel.sendApiCall(
      `addNewsLetterSubscription`,
      body,
      null,
      (result) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        if (result?.data?.status === 200) {
          toast_func("success", result?.data?.message);
        } else {
          setIsLoading(false);
          toast_func("warning", result?.data?.message);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log("the error is ===>", error);
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />
          <div className="newsletter-area-section pt-100 news-leeteee">
            <div className="container">
              <div className="section-title text-center">
                <h2>Subscribe to Our Newsletter</h2>
                <p className="margin-auto">
                  Receive, via email, the economic letter, tips, articles and
                  tools for entrepreneurs and more information about our
                  solutions and events.
                </p>
              </div>
              <div className="newsletter-area pt-45">
                {/* <form className="newsletter-form" data-toggle="validator" > */}
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  name="EMAIL"
                  required=""
                  autocomplete="off"
                />
                <button
                  onClick={() => newsSubscribe()}
                  className=""
                  type="submit"
                >
                  Subscribe
                </button>
                <div id="validator-newsletter" className="form-result"></div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Newsletter;
